<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.invite')" left-arrow @click-left="onClickLeft" />

  <!-- 海报 -->
  <div>
    <div v-if="parent.poster">
      <van-image :src="parent.poster">
        <template v-slot:loading>{{ t('loading') }}</template>
      </van-image>
      <div class="text-center text-md mt-4 text-red">{{ t('description.share') }}</div>
    </div>
    <div v-else>
      <van-loading size="24px" style="margin: 200px auto;" vertical>{{ t('generating') }}</van-loading>
    </div>

  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import parentAPI from '../../api/parent'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { GenerateParentPosterImpl, Parent, ParentImpl } from '../../types/parent'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.go(-1)
}

const store = useStore()
const parentId = store.getters.id

// 查询家长
const parent = reactive<Parent>(new ParentImpl(parentId))
const doFindParent = function () {
  parentAPI.findById(parentId).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
      if (!parent.poster) {
        doGenerateParentPoster()
      }
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// 生成海报
const doGenerateParentPoster = function () {
  const generateParentPoster = new GenerateParentPosterImpl(parent.id)
  parentAPI.generatePoster(generateParentPoster).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      parent.poster = result.data.poster
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

onMounted(() => {
  doFindParent()
})
</script>