import { Status } from "../../../acquiredCoupon"
import { Filters } from "./index"

export interface AcquiredCouponFilters extends Filters {
	parentId: string
	status?: Status
}

export class AcquiredCouponFiltersImpl implements AcquiredCouponFilters {
	parentId: string
	status?: Status

	constructor(parentId: string) {
		this.parentId = parentId
	}

	reset(): void {
		this.status = undefined
	}
}