<template>
  <!-- Form -->
  <van-form ref="updateParentEmailForm">
    <van-cell-group inset>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger">{{ message }}</div>
      <!-- Email -->
      <van-field v-model.trim="updateParentEmail.email" name="email" :label="t('label.email')" label-width="50" clearable :rules="[{ validator: emailValidator, trigger: 'onBlur' }]"></van-field>
      <!-- OTP -->
      <van-field type="digit" v-model.trim="updateParentEmail.otp" name="otp" :label="t('label.otp')" label-width="50" clearable :rules="[{ validator: otpValidator, trigger: 'onBlur' }]">
        <template #button>
          <van-button size="small" type="primary" @click="doSendUpdateEmailOtp" style="width: 100px;" :loading="sending" :disabled="counting">
            <van-count-down v-if="counting" :time="60 * 1000" format="ss" @finish="counting = false" />
            <span v-else>{{ t('button.get-otp') }}</span>
          </van-button>
        </template>
      </van-field>
    </van-cell-group>

    <!-- Button -->
    <div class="mx-4 my-4">
      <van-button round block type="primary" :loading="updating" @click="doUpdateParentEmail">{{ t('button.update-email') }}</van-button>
    </div>
  </van-form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { FormInstance, showFailToast, showSuccessToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import emailAPI from '../../api/email'
import parentAPI from '../../api/parent'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentEmail, UpdateParentEmailImpl } from '../../types/parent'
import { emailValidator, otpValidator } from '../../validators'

const props = defineProps<{
  parent: Parent
}>()

const { t } = useI18n({
  useScope: 'global'
})

const message = ref<string>('')
const updateParentEmail = reactive<UpdateParentEmail>(new UpdateParentEmailImpl(props.parent))
const updateParentEmailForm = ref<FormInstance>()

// 发送验证码
const counting = ref<boolean>(false)
const sending = ref<boolean>(false)
const doSendUpdateEmailOtp = function() {
  updateParentEmailForm.value?.validate('email').then(() => {
    if (updateParentEmail.email) {
      sending.value = true
      emailAPI.sendUpdateEmailOtp(updateParentEmail.email).then((response: AxiosResponse<Result<void>>) => {
        const result = response.data
        if (result.success) {
          counting.value = true
          showSuccessToast(t('message.send-otp-success'))
        } else {
          message.value = result.message
        }
      }).catch((error: AxiosError) => {
        showFailToast(error.message)
      }).finally(() => {
        sending.value = false
      })
    }
  }).catch(() => {})
}

// 更新邮箱
const updating = ref<boolean>(false)
const doUpdateParentEmail = function () {
  updateParentEmailForm.value?.validate().then(() => {
    updating.value = true
    parentAPI.updateEmail(updateParentEmail).then((response: AxiosResponse<Result<Parent>>) => {
      const result = response.data
      if (result.success) {
        updateParentEmail.otp = ''
        showSuccessToast(t('message.update-parent-email-success'))
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      showFailToast(error.message)
    }).finally(() => {
      updating.value = false
    })
  }).catch(() => {})
}

const refresh = function (parent: Parent) {
  updateParentEmail.refresh(parent)
}

defineExpose({
  refresh
})
</script>
