import { Locale } from 'vant'

import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'
import zhTW from 'vant/es/locale/lang/zh-TW'


export function changeVantLocale(locale: string) {
  switch (locale) {
    case 'en': Locale.use(locale, enUS); break
    case 'en-US': Locale.use(locale, enUS); break
    case 'zh-CN': Locale.use(locale, zhCN); break
    case 'zh-TW': Locale.use(locale, zhTW); break
    default: Locale.use(locale, zhCN)
  }
}