import { AcquiredCoupon } from "../types/acquiredCoupon"
import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import service from "./api-service"

const api = '/acquiredCoupon'

const acquiredCouponAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<AcquiredCoupon>>>(api + '/list', query)
  },

  findEnabled (parentId: string, rechargeCardId: string) {
    return service.get<Result<AcquiredCoupon[]>>(api + '/findEnabled', { 
      params: {
        parentId: parentId,
        rechargeCardId: rechargeCardId
      }
    })
  },
}

export default acquiredCouponAPI