<template>
  <van-grid v-if="editAvatar" :border="false" :column-num="5" :gutter="5" class="my-1">
    <van-grid-item v-for="studentAvatar in studentAvatars" :key="studentAvatar.id">
      <van-image :src="studentAvatar.url" round @click="doSelectStudentAvatar(studentAvatar)"></van-image>
    </van-grid-item>
  </van-grid>
  <van-cell v-else is-link center @click="doEditStudentAvatar">
    <template #title>
      <van-image :src="avatarURL" width="80" height="80" round></van-image>
    </template>
  </van-cell>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import studentAvatarAPI from '../../api/studentAvatar'
import { Result } from '../../types/common/result'
import { StudentAvatar } from '../../types/studentAvatar'
import { useModelWrapper } from '../../utils/modelWrapper'

export interface Props {
  avatar?: string
  edit: boolean
}

const props = withDefaults(defineProps<Props>(), {
  edit: true
})

const emit = defineEmits<{
  (e: 'update:avatar'): void
  (e: 'update:edit'): void
  (e: 'success', url: string): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const avatarURL = useModelWrapper(props, emit, 'avatar')
const editAvatar = useModelWrapper(props, emit, 'edit')

const studentAvatars = ref<StudentAvatar[]>()

const doFindAllStudentAvatar = function () {
  studentAvatarAPI.findAll().then((response: AxiosResponse<Result<StudentAvatar[]>>) => {
    const result = response.data
    if (result.success) {
      studentAvatars.value = result.data
      avatarURL.value = studentAvatars.value[0].url
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const doSelectStudentAvatar = function (studentAvatar: StudentAvatar) {
  avatarURL.value = studentAvatar.url
  emit('success', studentAvatar.url)
}

const doEditStudentAvatar = function () {
  editAvatar.value = true
  doFindAllStudentAvatar()
}

onMounted(() => {
  if (editAvatar.value) doFindAllStudentAvatar()
})
</script>
