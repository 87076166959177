<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.recharge')" left-arrow @click-left="onClickLeft"/>

  <!-- 列表 -->
  <div class="content">
    <!-- Recharge Card -->
    <recharge-card-card v-for="rechargeCard in rechargeCards" :key="rechargeCard.id" :recharge-card="rechargeCard"  :selected-recharge-card="selectedRechargeCard" @select="doSelectRechargeCard" />
    
    <!-- Coupon List -->
    <van-cell-group inset class="mt-4">
      <van-cell :title="t('label.coupon')" :value="selectAcquiredCouponMessage" is-link @click="doShowAcquiredCouponsPopup" />
    </van-cell-group>
    <van-popup v-model:show="showAcquiredCouponsPopup" round position="bottom" style="background-color: gainsboro;">
      <acquired-coupons :acquired-coupons="acquiredCoupons" @select="onSelectAcquiredCoupon"></acquired-coupons>
    </van-popup>

    <!-- Checkout Form -->
    <van-form ref="userCheckoutForm" class="mt-4">
      <van-cell-group inset>
        <!-- Alert -->
        <div v-if="message" class="alert alert-danger">{{ message }}</div>
        <!-- Email -->
        <van-field v-model.trim="userCheckout.parentEmail" name="parentEmail" :label="t('label.email')" :placeholder="t('placeholder.checkout-email')" label-width="50" clearable :rules="[{ validator: emailValidator, trigger: 'onBlur' }]"></van-field>
      </van-cell-group>

      <!-- Button -->
      <div class="mx-4 my-4">
        <van-button round block type="primary" :loading="checkouting" @click="doUserCheckout">{{ t('button.recharge') }}</van-button>
      </div>
    </van-form>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios';
import { FormInstance, showFailToast } from 'vant';
import { onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import acquiredCouponAPI from '../../api/acquiredCoupon';
import parentAPI from '../../api/parent';
import rechargeAPI from '../../api/recharge';
import rechargeCardAPI from '../../api/rechargeCard';
import AcquiredCoupons from '../../components/coupon/AcquiredCoupons.vue';
import RechargeCardCard from '../../components/recharge/RechargeCardCard.vue';
import { NumberFilter } from '../../filters/numberFilter';
import { useStore } from '../../store';
import { AcquiredCoupon, AcquiredCouponImpl } from '../../types/acquiredCoupon';
import { Result } from '../../types/common/result';
import { Parent, ParentImpl } from '../../types/parent';
import { Recharge, RechargeImpl, UserCheckout, UserCheckoutImpl } from '../../types/recharge';
import { RechargeCard, RechargeCardImpl } from '../../types/rechargeCard';
import { cloneFrom } from '../../utils/clone';
import { emailValidator } from '../../validators';

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()

const message = ref<string>('')
const checkouting = ref<boolean>(false)
const recharge = reactive<Recharge>(new RechargeImpl(''))
const userCheckout = reactive<UserCheckout>(new UserCheckoutImpl(store.getters.id))
const userCheckoutForm = ref<FormInstance>()

const parent = reactive<Parent>(new ParentImpl(store.getters.id))
const doFindParent = function () {
  parentAPI.findById(store.getters.id).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
      userCheckout.parentEmail = parent.email
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const doUserCheckout = function() {
  userCheckoutForm.value?.validate().then(() => {
    checkouting.value = true
    rechargeAPI.userCheckout(userCheckout).then((response: AxiosResponse<Result<Recharge>>) => {
      const result = response.data
      if (result.success) {
        cloneFrom(recharge, result.data)
        if (recharge.stripeSessionUrl) {
          location.href = recharge.stripeSessionUrl
        }
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      showFailToast(error.message)
    }).finally(() => {
      checkouting.value = false
    })
  }).catch(() => {})    
}

const rechargeCards = ref<RechargeCard[]>()
const selectedRechargeCard = reactive<RechargeCard>(new RechargeCardImpl(''))
const doFindAllRechargeCard = function () {
  rechargeCardAPI.findAllPublished().then((response: AxiosResponse<Result<RechargeCard[]>>) => {
    const result = response.data
    if (result.success) {
      rechargeCards.value = result.data
      rechargeCards.value.forEach(rechargeCard => {
        if (rechargeCard.recommended) {
          cloneFrom(selectedRechargeCard, rechargeCard)
          userCheckout.rechargeCardId = selectedRechargeCard.id
        }
      })
      doFindAllAcquiredCoupon(selectedRechargeCard.id)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const doSelectRechargeCard = function (rechargeCard: RechargeCard) {
  cloneFrom(selectedRechargeCard, rechargeCard)
  userCheckout.rechargeCardId = selectedRechargeCard.id
  userCheckout.acquiredCouponId = undefined
  doFindAllAcquiredCoupon(selectedRechargeCard.id)
}

const selectAcquiredCouponMessage = ref(t('message.select-enabled-coupon', { count: 0 }))
const showAcquiredCouponsPopup = ref(false)
const doShowAcquiredCouponsPopup = function () {
  if (userCheckout.rechargeCardId) {
    if (acquiredCoupons.value.length > 0) {
      showAcquiredCouponsPopup.value = true
    }
  } else {
    showFailToast(t('message.select-recharge-card'))
  } 
}
const acquiredCoupons = ref<AcquiredCoupon[]>([])
const selectedAcquiredCoupon = reactive<AcquiredCoupon>(new AcquiredCouponImpl(''))
const doFindAllAcquiredCoupon = function (rechargeCardId: string) {
  acquiredCouponAPI.findEnabled(parent.id, rechargeCardId).then((response: AxiosResponse<Result<AcquiredCoupon[]>>) => {
    const result = response.data
    if (result.success) {
      acquiredCoupons.value = result.data
      selectAcquiredCouponMessage.value = t('message.select-enabled-coupon', { count: acquiredCoupons.value.length })
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}
const onSelectAcquiredCoupon = function (acquiredCoupon: AcquiredCoupon) {
  selectAcquiredCouponMessage.value = '- $' + NumberFilter.money(acquiredCoupon.couponValue)
  cloneFrom(selectedAcquiredCoupon, acquiredCoupon)
  userCheckout.acquiredCouponId = acquiredCoupon.id
  showAcquiredCouponsPopup.value = false
}

onMounted(() => {
  doFindParent()
  doFindAllRechargeCard()
})
</script>