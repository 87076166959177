<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.enrollments')" left-arrow @click-left="onClickLeft" />

  <!-- 筛选栏 -->
  <van-dropdown-menu>
    <van-dropdown-item v-model="filters.studentId" :options="studentDropdownItemOptions" @change="onStudentChange" />
    <van-dropdown-item v-model="filters.status" :options="EnrollmentStatusOptions.dropdownItemOptions" @change="onStatusChange" />
  </van-dropdown-menu>

  <!-- 列表 -->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list v-model:loading="loading" :finished="finished" :finished-text="t('list.text.finished')" v-model:error="error" :error-text="t('list.text.error')" @load="onLoad">
      <enrollment-card v-for="enrollment in enrollments" :key="enrollment.id" :enrollment="enrollment" @suspended="doListEnrollment" @canceled="doListEnrollment"></enrollment-card>
    </van-list>
  </van-pull-refresh>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { DropdownItemOption, showFailToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import enrollmentAPI from '../../api/enrollment'
import studentAPI from '../../api/student'
import EnrollmentCard from '../../components/enrollment/EnrollmentCard.vue'
import { useStore } from '../../store'
import { EnrollmentStatusOptions } from '../../types/common/option/enrollmentOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { EnrollmentFilters, EnrollmentFiltersImpl } from '../../types/common/query/filters/enrollmentFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { EnrollmentSearcherImpl } from '../../types/common/query/searcher/enrollmentSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Enrollment } from '../../types/enrollment'
import { Student } from '../../types/student'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()
const parentId = store.getters.id

// Query enrollment
const enrollments = ref<Enrollment[]>([])

const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<EnrollmentFilters>(new EnrollmentFiltersImpl(parentId))
const searcher = reactive<Searcher>(new EnrollmentSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const finished = ref<boolean>(false)
const error = ref<boolean>(false)
const loading = ref<boolean>(false)
const doListEnrollment = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  enrollmentAPI.list(query).then((response: AxiosResponse<Result<ListObject<Enrollment>>>) => {
    if (refreshing.value) {
      refreshing.value = false
      enrollments.value = []
    }

    const result = response.data
    if (result.success) {
      const listObject = result.data
      listObject.objects.forEach(object => enrollments.value?.push(object))
      
      pagination.currentPage = pagination.currentPage + 1
      pagination.total = listObject.total

      if (enrollments.value?.length! >= pagination.total) finished.value = true
    } else {
      showFailToast(result.message)
      error.value = true
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

// Find student
const studentDropdownItemOptions = ref<DropdownItemOption[]>([{text: t('enrollment.student.all'), value: ''}])
const doFindStudent = function () {
  studentAPI.findByParentId(parentId).then((response: AxiosResponse<Result<Student[]>>) => {
    const result = response.data
    if (result.success) {
      const students = result.data
      students.forEach(student => studentDropdownItemOptions.value.push({text: student.nickname!, value: student.id}))
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const onStudentChange = function () {
  refreshing.value = true
  pagination.reset()
  doListEnrollment()
}

const onStatusChange = function () {
  refreshing.value = true
  pagination.reset()
  doListEnrollment()
}

const onLoad = function () {
  doListEnrollment()
}

const refreshing = ref<boolean>(false)
const onRefresh = function () {
  finished.value = false
  loading.value = true
  pagination.reset()
  onLoad()
}

onMounted(() => {
  doFindStudent()
})
</script>
