import i18n from "../i18n"
import { Level } from "../types/teacher"

const vueI18n = i18n.global

export class TeacherFilter {
  static certified (certified?: boolean): string {
    if (certified == undefined) return ''
    else return certified? vueI18n.t('teacher.certified.yes') : vueI18n.t('teacher.certified.no')
  }

  static fullName (firstName?: string, lastName?: string): string {
    return firstName + ' ' + lastName
  }

  static level (level?: Level): string {
    switch (level) {
      case Level.BRONZE:
        return vueI18n.t('teacher.level.bronze')
      case Level.SILVER:
        return vueI18n.t('teacher.level.silver')
      case Level.GOLD:
        return vueI18n.t('teacher.level.gold')
      case Level.PLATINUM:
        return vueI18n.t('teacher.level.platinum')
      default:
        return '' 
    }
  }
}