<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.service')" left-arrow @click-left="onClickLeft" />

  <!-- 服务方式 -->
  <div class="d-flex justify-content-center" style="margin-top: 100px;">
    <wechat-service></wechat-service>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import WechatService from '../../components/service/WechatService.vue';

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.go(-1)
}
</script>
