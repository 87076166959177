export enum Status {
  ALL = '',
  SIGNUP = 'SIGNUP', // 已注册
  RECHARGE = 'RECHARGE' // 已充值
}

export interface Recommendation {
  id: string
  status: Status
  dateTime: Date

  referrerId: string
  referrerNickname: string

  referralId: string
  referralNickname: string
}