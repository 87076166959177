<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.recharges')" left-arrow @click-left="onClickLeft" />

  <!-- 筛选栏 -->
  <van-dropdown-menu>
    <van-dropdown-item v-model="filters.status" :options="RechargeStatusOptions.dropdownItemOptions" @change="onStatusChange" />
    <van-dropdown-item v-model="filters.type" :options="RechargeTypeOptions.dropdownItemOptions" @change="onTypeChange" />
  </van-dropdown-menu>

  <!-- 列表 -->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list v-model:loading="loading" :finished="finished" :finished-text="t('list.text.finished')" v-model:error="error" :error-text="t('list.text.error')" @load="onLoad">
      <recharge-card v-for="recharge in recharges" :key="recharge.id" :recharge="recharge"></recharge-card>
    </van-list>
  </van-pull-refresh>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import rechargeAPI from '../../api/recharge'
import RechargeCard from '../../components/recharge/RechargeCard.vue'
import { useStore } from '../../store'
import { RechargeStatusOptions, RechargeTypeOptions } from '../../types/common/option/rechargeOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RechargeFilters, RechargeFiltersImpl } from '../../types/common/query/filters/rechargeFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { RechargeSearcherImpl } from '../../types/common/query/searcher/rechargeSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Recharge } from '../../types/recharge'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()
const parentId = store.getters.id

// Query recharge
const recharges = ref<Recharge[]>([])

const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RechargeFilters>(new RechargeFiltersImpl(parentId))
const searcher = reactive<Searcher>(new RechargeSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const finished = ref<boolean>(false)
const error = ref<boolean>(false)
const loading = ref<boolean>(false)
const doListRecharge = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  rechargeAPI.list(query).then((response: AxiosResponse<Result<ListObject<Recharge>>>) => {
    if (refreshing.value) {
      refreshing.value = false
      recharges.value = []
    }

    const result = response.data
    if (result.success) {
      const listObject = result.data
      listObject.objects.forEach(object => recharges.value?.push(object))
      
      pagination.currentPage = pagination.currentPage + 1
      pagination.total = listObject.total

      if (recharges.value?.length! >= pagination.total) finished.value = true
    } else {
      showFailToast(result.message)
      error.value = true
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onTypeChange = function () {
  refreshing.value = true
  pagination.reset()
  doListRecharge()
}

const onStatusChange = function () {
  refreshing.value = true
  pagination.reset()
  doListRecharge()
}

const onLoad = function () {
  doListRecharge()
}

const refreshing = ref<boolean>(false)
const onRefresh = function () {
  finished.value = false
  loading.value = true
  pagination.reset()
  onLoad()
}
</script>
