<template>
  <van-cell-group inset class="mt-4">
    <van-cell :title="t('table.recharge.status')" :value="RechargeFilter.status(recharge.status)"></van-cell>
    <van-cell :title="t('table.recharge.type')" :value="RechargeFilter.type(recharge.type)"></van-cell>
    <van-cell :title="t('table.recharge.lessons')" :value="recharge.lessons"></van-cell>
    <van-cell v-if="recharge.rechargeCardName" :title="t('table.recharge.recharge-card')" :value="recharge.rechargeCardName"></van-cell>
    <van-cell v-if="recharge.couponName" :title="t('table.recharge.coupon')" :value="recharge.couponName"></van-cell>
    <van-cell :title="t('table.recharge.paid')" :value="EnumFilter.currency(recharge.currency) + NumberFilter.money(recharge.paid)"></van-cell>
    <van-cell :title="t('table.recharge.remark')" :label="recharge.remark"></van-cell>
  </van-cell-group>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { RechargeFilter } from '../../filters/rechargeFilter'
import { Recharge } from '../../types/recharge'

const props = defineProps<{
  recharge: Recharge
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>
