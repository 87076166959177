import { Currency, Domain } from "./enums"

export enum Type {
	ALL = '',
	USER = 'USER',
	SYSTEM = 'SYSTEM',
	STAFF = 'STAFF'
}

export enum Category {
	ALL = '',
	TUITION = 'TUITION',
	REWARD = 'REWARD',
	COMPENSATION = 'COMPENSATION'
}

export enum Method {
	ALL = '',
	CARD = 'CARD',
	ALIPAY = 'ALIPAY',
	WECHAT_PAY = 'WECHAT_PAY',
	CASH = 'CASH',
	OTHERS = 'OTHERS'
}

export enum Status {
	ALL = '',
	CREATED = 'CREATED',
	COMPLETED = 'COMPLETED',
	VOIDED = 'VOIDED'
}

export interface Recharge {
	id: string
	status?: Status
	type?: Type
	category?: Category
	method?: Method

	lessons?: number
	currency?: Currency
	paid?: number
	remark?: string
	
	createdDateTime?: Date
	completedDateTime?: Date
	voidedDateTime?: Date

	stripeSessionId?: string
	stripeSessionUrl?: string

	parentId?: string
	parentNickname?: string
	parentMobile?: string
	parentEmail?: string

	rechargeCardId?: string
	rechargeCardName?: string

	acquiredCouponId?: string
	couponId?: string
	couponName?: string
	couponValue?: number
}

export class RechargeImpl implements Recharge {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface UserCheckout {
	domain: Domain
	parentId: string
	parentEmail?: string
	rechargeCardId?: string
	acquiredCouponId?: string
}

export class UserCheckoutImpl implements UserCheckout {
	domain: Domain
	parentId: string

	constructor(parentId: string) {
		this.domain = Domain.PARENT
		this.parentId = parentId
	}
}