import { Certificate } from "./certificate";
import { Accent, Country } from "./enums";

export enum Level {
	ALL = '',
	BRONZE = 'BRONZE', // 铜牌
  SILVER = 'SILVER', // 银牌
  GOLD = 'GOLD', // 金牌
	PLATINUM = 'PLATINUM' // 白金
}

export interface Teacher {
	id: string
	name?: string
	nickname?: string
	avatar?: string
	introduction?: string
	show?: string
	displayCountry?: Country
	accent?: Accent
	certificates?: Certificate[]
	level?: Level
	certified?: boolean
	rank?: number,
	fee?: number
}

export class TeacherImpl implements Teacher {
	id: string

	constructor(id: string) {
		this.id = id
	}
}