import { DropdownItemOption } from "vant"
import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Level } from "../../teacher"

const vueI18n = i18n.global

export class TeacherAvailableOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('teacher.available.all'), undefined),
    new OptionImpl(vueI18n.t('teacher.available.yes'), true),
		new OptionImpl(vueI18n.t('teacher.available.no'), false),
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('teacher.available.all'), value: ''},
		{text: vueI18n.t('teacher.available.yes'), value: 'true'},
		{text: vueI18n.t('teacher.available.no'), value: 'false'}
	]
}

export class TeacherCertifiedOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('teacher.certified.all'), undefined),
    new OptionImpl(vueI18n.t('teacher.certified.yes'), true),
		new OptionImpl(vueI18n.t('teacher.certified.no'), false),
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('teacher.certified.all'), value: ''},
		{text: vueI18n.t('teacher.certified.yes'), value: 'true'},
		{text: vueI18n.t('teacher.certified.no'), value: 'false'}
	]
}

export class TeacherLevelOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('teacher.level.all'), Level.ALL),
    new OptionImpl(vueI18n.t('teacher.level.bronze'), Level.BRONZE),
		new OptionImpl(vueI18n.t('teacher.level.silver'), Level.SILVER),
		new OptionImpl(vueI18n.t('teacher.level.gold'), Level.GOLD),
		new OptionImpl(vueI18n.t('teacher.level.platinum'), Level.PLATINUM),
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('teacher.level.all'), value: Level.ALL},
		{text: vueI18n.t('teacher.level.bronze'), value: Level.BRONZE},
		{text: vueI18n.t('teacher.level.silver'), value: Level.SILVER},
		{text: vueI18n.t('teacher.level.gold'), value: Level.GOLD},
		{text: vueI18n.t('teacher.level.platinum'), value: Level.PLATINUM}
	]
}