<template>
  <van-popup v-model:show="showPopup" position="top">
    <van-nav-bar class="navbar" :left-text="t('label.locale')" @click-right="showPopup = false">
      <template #right>
        <van-icon name="cross" size="18" />
      </template>
    </van-nav-bar>
    <van-cell-group>
      <van-cell v-for="(availableLocale, index) in availableLocales" :key="index" :title="LocaleFilter.locale(availableLocale)" @click="doChangeLocale(availableLocale)">
        <template #right-icon>
          <van-icon v-if="locale === availableLocale" name="success" class="icon" />
        </template>
      </van-cell>
    </van-cell-group>
  </van-popup>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { LocaleFilter } from '../../filters/localeFilter'
import { Actions, useStore } from '../../store'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'update:show'): void
}>()

const { t, locale, availableLocales } = useI18n({
  useScope: 'global'
})

availableLocales.forEach( availableLocale => {
  availableLocale
})

const showPopup = useModelWrapper(props, emit, 'show')

const store = useStore()
const doChangeLocale = function(selectedLocale: object) {
  locale.value = selectedLocale
  store.dispatch(Actions.CHANGE_LOCALE, locale.value)
  showPopup.value = false
}
</script>

<style scoped>
.navbar {
  --van-nav-bar-icon-color: var(--van-black);
  --van-nav-bar-text-color: var(--van-black);
}

.icon {
  font-size: 16px;
  line-height: inherit;
}
</style>