import i18n from "../i18n"
import { CancelReason, Status } from "../types/arrangement"

const vueI18n = i18n.global

export class ArrangementFilter {
  static status (status?: Status): string {
    switch (status) {
      case Status.BOOKED:
        // @ts-ignore
        return vueI18n.t('arrangement.status.booked')
      case Status.FINISHED:
        // @ts-ignore
        return vueI18n.t('arrangement.status.finished')
      case Status.CANCELED:
        // @ts-ignore
        return vueI18n.t('arrangement.status.canceled')
      default:
        return '' 
    }
  }

  static cancelReason (cancelReason?: CancelReason): string {
    switch (cancelReason) {
      case CancelReason.LEAVE_STUDENT:
        return vueI18n.t('arrangement.cancel-reason.leave-student')
      case CancelReason.LEAVE_TEACHER:
        return vueI18n.t('arrangement.cancel-reason.leave-teacher')
      case CancelReason.ABSENT_STUDENT:
        return vueI18n.t('arrangement.cancel-reason.absent-student')
      case CancelReason.ABSENT_TEACHER:
        return vueI18n.t('arrangement.cancel-reason.absent-teacher')
      case CancelReason.OTHERS:
        return vueI18n.t('arrangement.cancel-reason.others')
      default:
        return '' 
    }
  }

  static trial (trial?: boolean): string {
    if (trial == undefined) return ''
    else return trial? vueI18n.t('arrangement.trial.yes') : vueI18n.t('arrangement.trial.no')
  }
}