<template>
  <van-cell-group inset class="mt-4">
    <van-cell :title="t('table.consume.student')" :value="consume.studentNickname"></van-cell>
    <van-cell :title="t('table.consume.lessons')" :value="consume.lessons"></van-cell>
    <van-cell :title="t('table.consume.time')" :value="DateTimeFilter.datetime(consume.dateTime)"></van-cell>
    <van-cell :title="t('table.consume.remark')" :label="consume.remark"></van-cell>
  </van-cell-group>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { Consume } from '../../types/consume'

const props = defineProps<{
  consume: Consume
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>
