import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Status } from "../../acquiredCoupon"

const vueI18n = i18n.global

export class AcquiredCouponStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('acquired-coupon.status.acquired'), Status.ACQUIRED),
    new OptionImpl(vueI18n.t('acquired-coupon.status.expired'), Status.EXPIRED),
		new OptionImpl(vueI18n.t('acquired-coupon.status.used'), Status.USED)
	]
}