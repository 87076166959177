import i18n from "../i18n"
import { Type } from "../types/course"

const vueI18n = i18n.global

export class CourseFilter {

  static kind (type?: Type, trial?: boolean): string {
    return this.type(type) + ' ' + this.trial(trial)
  }

  static type (type?: Type): string {
    switch (type) {
      case Type.VIP:
        return vueI18n.t('course.type.vip')
      case Type.GROUP:
        return vueI18n.t('course.type.group')
      default:
        return '' 
    }
  }

  static trial (trial?: boolean): string {
    if (trial == undefined) return ''
    else return trial? vueI18n.t('course.trial.yes') : vueI18n.t('course.trial.no')
  }

  static ready (ready?: boolean): string {
    if (ready == undefined) return ''
    else return ready? vueI18n.t('course.ready.yes') : vueI18n.t('course.ready.no')
  }

  static lessonDuration(lessonDuration?: number): string {
    if (lessonDuration) return lessonDuration + vueI18n.t('unit.minitue')
    else return ''
  }

  static lessonCount(lessonCount?: number): string {
    if (lessonCount) return lessonCount + vueI18n.t('unit.lessons')
    else return ''
  }
}