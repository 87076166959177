<template>
  <van-tabs animated type="card">
    <van-tab v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :title="DateTimeFilter.shortDayOfWeek(dayOfWeek)">
      <it-time-slots class="mx-4 my-4" :teaching-time-slots-id="teachingTimeSlots.id" :time-slots="teachingTimeSlots.timeSlots.get(dayOfWeek) || []" :enrollment-id="enrollmentId"></it-time-slots>
    </van-tab>
  </van-tabs>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import teachingTimeSlotsAPI from '../../api/teachingTimeSlots'
import ItTimeSlots from '../../components/ui/time/ItTimeSlots.vue'
import { createDayOfWeeks } from '../../factory'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { Result } from '../../types/common/result'
import { Teacher } from '../../types/teacher'
import { TeachingTimeSlots, TeachingTimeSlotsImpl } from '../../types/teachingTimeSlots'
import { cloneFrom } from '../../utils/clone'

const props = defineProps<{
  teacher: Teacher,
  enrollmentId: string
}>()

const { t } = useI18n({
  useScope: 'global'
})

const loading = ref<boolean>(false)

const teachingTimeSlots = reactive<TeachingTimeSlots>(new TeachingTimeSlotsImpl(props.teacher.id))
const dayOfWeeks = createDayOfWeeks()

onMounted(() => {
  doFindTeachingTimeSlots()
})

const doFindTeachingTimeSlots = function () {
  loading.value = true
  teachingTimeSlotsAPI.findByTeacherId(props.teacher.id).then((response: AxiosResponse<Result<TeachingTimeSlots>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teachingTimeSlots, result.data)
      // 根据本地时间排序
      dayOfWeeks.forEach(dayOfWeek => {
        const timeSlots = teachingTimeSlots.timeSlots.get(dayOfWeek)
        timeSlots?.sort((timeSlot1, timeSlot2) => {
          return DateTimeFilter.time(timeSlot1.startTime).localeCompare(DateTimeFilter.time(timeSlot2.startTime))
        })
      })
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const refresh = function () {
  doFindTeachingTimeSlots()
}

defineExpose({
  refresh
})
</script>