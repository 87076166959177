<template>
  <van-cell-group inset>
    <van-cell :title="t('label.teacher.country')" :value="EnumFilter.country(teacher.displayCountry)" />
    <van-cell :title="t('label.teacher.accent')" :value="EnumFilter.accent(teacher.accent)" />
    <van-cell :title="t('label.teacher.level')" :value="TeacherFilter.level(teacher.level)" />
    <van-cell :title="t('label.teacher.certified')" :value="TeacherFilter.certified(teacher.certified)" />
    <van-cell :title="t('label.teacher.fee')" :value="'$' + NumberFilter.money(teacher.fee) + ' / ' + t('unit.lesson')" />
  </van-cell-group>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { TeacherFilter } from '../../filters/teacherFilter'
import { Teacher } from '../../types/teacher'

const props = defineProps<{
  teacher: Teacher
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>
