<template>
  <!-- 标签栏 -->
  <!-- TODO -->
  <!-- <van-tabs sticky animated swipeable v-model:active="tab" @click-tab="onClickTab">
    <van-tab v-for="(option, index) in SubjectOptions.options" :key="index" :title="option.label">
      <div v-if="series?.length && series.length > 0">
        <serie-courses v-for="serie in series" :key="serie.id" :serie="serie"></serie-courses>
      </div>
      <van-empty v-else :description="t('table.course.caption')" />
    </van-tab>
  </van-tabs> -->

  <serie-courses v-for="serie in series" :key="serie.id" :serie="serie"></serie-courses>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import serieAPI from '../../api/serie'
import SerieCourses from '../../components/course/SerieCourses.vue'
import { SubjectOptions } from '../../types/common/option/subjectOptions'
import { Result } from '../../types/common/result'
import { CourseSerie, Subject } from '../../types/serie'

const { t } = useI18n({
  useScope: 'global'
})

const tab = ref<number>(0)
const series = ref<CourseSerie[]>()

const doFindAllSerie = function () {
  serieAPI.findAllVisible().then((response: AxiosResponse<Result<CourseSerie[]>>) => {
    const result = response.data
    if (result.success) {
      series.value = result.data
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const onClickTab = function () {
  const subject = SubjectOptions.options[tab.value].value as Subject
  if (subject === Subject.ALL) {
    doFindAllSerie()
  } else {
    doFindSerieBySubject(subject)
  }
}

const doFindSerieBySubject = function (subject: Subject) {
  serieAPI.findVisibleBySubject(subject).then((response: AxiosResponse<Result<CourseSerie[]>>) => {
    const result = response.data
    if (result.success) {
      series.value = result.data
    } else {
      showFailToast(result.message)
    }						
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

onMounted(() => {
  doFindAllSerie()
})
</script>
