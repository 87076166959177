<template>
  <div class="d-flex flex-column align-items-center">
    <van-image :src="image" width="200" height="200"></van-image>
    <div class="mt-2 text-md">{{ t('description.service-wechat') }}</div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n({
  useScope: 'global'
})

let image: string = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    image = '/images/service/wechat/cetuschool.jpg'
    break
  }
  case 'DDTalk': {
    image = '/images/service/wechat/ddtalk.jpg'
    break
  }
}
</script>