<template>
  <!-- Form -->
  <van-form ref="updateParentInfoForm">
    <van-cell-group inset>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger">{{ message }}</div>

      <!-- Nickname -->
      <van-field v-model.trim="updateParentInfo.nickname" name="nickname" :label="t('label.nickname')" input-align="right" clearable :rules="[{ validator: mustInputValidator, trigger: 'onBlur' }]"></van-field>
      <!-- Name -->
      <van-field v-model.trim="updateParentInfo.name" name="name" :label="t('label.name')" input-align="right" clearable :rules="[{ validator: mustInputValidator, trigger: 'onBlur' }]"></van-field>
    </van-cell-group>

    <!-- Button -->
    <div class="mx-4 my-4">
      <van-button round block type="primary" :loading="updating" @click="doUpdateParentInfo">{{ t('button.update') }}</van-button>
    </div>
  </van-form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { FormInstance, showFailToast, showSuccessToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentInfo, UpdateParentInfoImpl } from '../../types/parent'
import { mustInputValidator } from '../../validators'

const props = defineProps<{
  parent: Parent
}>()

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()

const message = ref<string>('')

const updateParentInfo = reactive<UpdateParentInfo>(new UpdateParentInfoImpl(props.parent))
const updateParentInfoForm = ref<FormInstance>()

const updating = ref<boolean>(false)
const doUpdateParentInfo = function () {
  updateParentInfoForm.value?.validate().then(() => {
    updating.value = true
    parentAPI.updateInfo(updateParentInfo).then((response: AxiosResponse<Result<Parent>>) => {
      const result = response.data
      if (result.success) {
        store.dispatch(Actions.UPDATE_PARENT, result.data).then(() => {
          showSuccessToast(t('message.update-parent-info-success'))
        })
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      showFailToast(error.message)
    }).finally(() => {
      updating.value = false
    })
  })
}

const refresh = function (parent: Parent) {
  updateParentInfo.refresh(parent)
}

defineExpose({
  refresh
})
</script>
