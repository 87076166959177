<template>
  <router-link :to="{name: 'profile', params: {id: parent.id}}">
    <div class="info d-flex align-items-center justify-content-between text-white">
      <div class="d-flex align-items-center ms-4">
        <van-image :src="logo" width="80" height="80" round class="bg-white"></van-image>
        <div class="ms-4">{{ parent.nickname }}</div>
      </div>
      <van-icon name="arrow" class="me-4" />
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useStore } from '../../store';

let logo: string = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    logo = '/images/logo/cetuschool.png'
    break
  }
  case 'DDTalk': {
    logo = '/images/logo/ddtalk.png'
    break
  }
}

const store = useStore()
const parent = store.state.parent
</script>

<style scoped>
.info {
  background-color: var(--van-primary-color);
  height: 140px;
}
</style>