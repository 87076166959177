import { Result } from "../types/common/result"
import { RechargeCard } from "../types/rechargeCard"
import service from "./api-service"

const api = '/rechargeCard'

const rechargeCardAPI = {
  findAllPublished () {
    return service.get<Result<RechargeCard[]>>(api + '/findAllPublished')
  },
  
  findById (id: string) {
    return service.get<Result<RechargeCard>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  }
}

export default rechargeCardAPI