import i18n from "../i18n"
import { Status } from "../types/recommendation"

const vueI18n = i18n.global

export class RecommendationFilter {
  static status (status?: Status): string {
    switch (status) {
      case Status.SIGNUP:
        return vueI18n.t('recommendation.status.signup')
      case Status.RECHARGE:
        return vueI18n.t('recommendation.status.recharge')
      default:
        return '' 
    }
  }
}