import { Result } from "../types/common/result"
import { CreateStudent, Student, UpdateStudentAvatar, UpdateStudentInfo } from "../types/student"
import service from "./api-service"
import fileService from "./file-service"

const api = '/student'

const studentAPI = {
  findById (id: string) {
    return service.get<Result<Student>>(api + '/findById', { 
        params: {
            id: id
        }
    })
  },

  findByParentId (parentId: string) {
    return service.get<Result<Student[]>>(api + '/findByParentId', { 
        params: {
          parentId: parentId
        }
    })
  },

  create (createStudent: CreateStudent) {
    return service.post<Result<Student>>(api, createStudent)
  },

  uploadAvatar (file: File) {
    let formData = new FormData()
    formData.append('file', file)
    return fileService.post<Result<string>>(api + '/uploadAvatar', formData)
  },

  updateInfo (updateStudentInfo: UpdateStudentInfo) {
    return service.put<Result<Student>>(api + '/updateInfo', updateStudentInfo)
  },

  updateAvatar (updateStudentAvatar: UpdateStudentAvatar) {
    return service.put<Result<Student>>(api + '/updateAvatar', updateStudentAvatar)
  },

  deleteById (id: string) {
    return service.delete<Result<void>>(api + '/deleteById', {
        params: {
            id: id
        }
    })
  }
}

export default studentAPI