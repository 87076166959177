<template>
  <van-cell-group inset>
    <student-avatar-selecter v-model:avatar="updateStudentAvatar.avatar" v-model:edit="edit" @success="doUpdateStudentAvatar"></student-avatar-selecter>
  </van-cell-group>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast, showSuccessToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import studentAPI from '../../api/student'
import { Result } from '../../types/common/result'
import { Student, UpdateStudentAvatar, UpdateStudentAvatarImpl } from '../../types/student'
import StudentAvatarSelecter from './StudentAvatarSelecter.vue'

const props = defineProps<{
  student: Student
}>()

const { t } = useI18n({
  useScope: 'global'
})

const edit = ref<boolean>(false)

const updateStudentAvatar = reactive<UpdateStudentAvatar>(new UpdateStudentAvatarImpl(props.student))

const doUpdateStudentAvatar = function () {
  studentAPI.updateAvatar(updateStudentAvatar).then((response: AxiosResponse<Result<Student>>) => {
    const result = response.data
    if (result.success) {
      edit.value = false
      showSuccessToast(t('message.update-student-avatar-success'))
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const refresh = function (student: Student) {
  updateStudentAvatar.refresh(student)
}

defineExpose({
  refresh
})
</script>
