<template>
  <van-tabs sticky animated swipeable v-model:active="activeTab" @click-tab="onClickTab">
    <van-tab v-for="(dayOfWeek, index) in dayOfWeeks" :key="index" :title="DateTimeFilter.dayOfWeek(dayOfWeek)">
      <div v-if="arrangements?.length && arrangements.length > 0">
        <arrangement-info-card v-for="arrangement in arrangements" :key="arrangement.id" :arrangement="arrangement" @cancel="onCancelArrangement"></arrangement-info-card>
      </div>
      <van-empty v-else :description="t('table.arrangement.caption')" />
    </van-tab>
  </van-tabs>

  <cancel-arrangement-popup :arrangementId="arrangementId" v-model:show="showCancelArrangementPopup" @success="onCancelArrangementSuccess"></cancel-arrangement-popup>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import arrangementAPI from '../../api/arrangement'
import ArrangementInfoCard from '../../components/arrangement/ArrangementInfoCard.vue'
import CancelArrangementPopup from '../../components/arrangement/CancelArrangementPopup.vue'
import { createDayOfWeeks } from '../../factory'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { useStore } from '../../store'
import { Arrangement } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { getThisDateOfWeek, getToday, getWeekday } from '../../utils/dateTimeUtils'

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()
const parentId = store.getters.id

const arrangements = ref<Arrangement[]>([])

// Find arrangement
const finding = ref<boolean>(false)
const doFindArrangement = function (date: Date) {
  finding.value = true
  arrangementAPI.findByParentIdAndDate(parentId, date).then((response: AxiosResponse<Result<Arrangement[]>>) => {
    const result = response.data
    if (result.success) {
      arrangements.value = result.data
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    finding.value = false
  })
}

const dayOfWeeks = createDayOfWeeks()
const activeDate = ref<Date>(getToday())
const activeTab = ref<number>(getWeekday(activeDate.value) - 1)
const onClickTab = function () {
  activeDate.value = getThisDateOfWeek(activeTab.value)
  doFindArrangement(activeDate.value)
}

const arrangementId = ref<string>('')

// Cancel Arrangement
const showCancelArrangementPopup = ref<boolean>(false)
const onCancelArrangement = function(id: string) {
  arrangementId.value = id
  showCancelArrangementPopup.value = true
}
const onCancelArrangementSuccess = function () {
  doFindArrangement(activeDate.value)
}

onMounted(() => {
  doFindArrangement(activeDate.value)
})
</script>
