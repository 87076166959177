import { createRouter, createWebHistory, NavigationGuardNext, RouteRecordRaw } from 'vue-router'
import { store } from '../store'
import Auth from '../views/Auth.vue'
import Coupons from '../views/coupon/Coupons.vue'
import Course from '../views/course/Course.vue'
import Courses from '../views/course/Courses.vue'
import Enrolled from '../views/course/Enrolled.vue'
import Enrollments from '../views/course/Enrollments.vue'
import Consumes from '../views/finance/Consumes.vue'
import Recharge from '../views/finance/Recharge.vue'
import Recharged from '../views/finance/Recharged.vue'
import Recharges from '../views/finance/Recharges.vue'
import Home from '../views/home/Home.vue'
import Invite from '../views/me/Invite.vue'
import Me from '../views/me/Me.vue'
import Profile from '../views/me/Profile.vue'
import Recommendations from '../views/me/Recommendations.vue'
import Security from '../views/me/Security.vue'
import Service from '../views/me/Service.vue'
import Settings from '../views/me/Settings.vue'
import Students from '../views/me/Students.vue'
import Arrange from '../views/schedule/Arrange.vue'
import Arrangements from '../views/schedule/Arrangements.vue'
import ReviewArrangement from '../views/schedule/ReviewArrangement.vue'
import Schedule from '../views/schedule/Schedule.vue'
import Teacher from '../views/schedule/Teacher.vue'
import CreateStudent from '../views/student/CreateStudent.vue'
import StudentProfile from '../views/student/StudentProfile.vue'
import Supplement from '../views/Supplement.vue'
import Welcome from '../views/Welcome.vue'

const routes: Array<RouteRecordRaw> = [
  {
    name: 'auth',
    path: '/auth/:to?',
    component: Auth,
    meta: {
      tabbar: false,
      auth: false
    }
  },
  {
    name: 'supplement',
    path: '/supplement',
    component: Supplement,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'welcome',
    path: '/welcome',
    component: Welcome,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'home',
    path: '/home/:promoteCode?',
    component: Home,
    meta: {
      tabbar: true,
      auth: false
    }
  },
  {
    name: 'courses',
    path: '/courses',
    component: Courses,
    meta: {
      tabbar: true,
      auth: true
    }
  },
  {
    name: 'course',
    path: '/course/:id',
    component: Course,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'enrolled',
    path: '/enrolled/:enrollmentId',
    component: Enrolled,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'enrollments',
    path: '/enrollments',
    component: Enrollments,
    meta: {
      tabbar: true,
      auth: true
    }
  },
  {
    name: 'arrange',
    path: '/arrange/:enrollmentId',
    component: Arrange,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'teacher',
    path: '/teacher/:id/:enrollmentId',
    component: Teacher,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'schedule',
    path: '/schedule',
    component: Schedule,
    meta: {
      tabbar: true,
      auth: true
    }
  },
  {
    name: 'arrangements',
    path: '/arrangements',
    component: Arrangements,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'reviewArrangement',
    path: '/reviewArrangement/:arrangementId',
    component: ReviewArrangement,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'me',
    path: '/me',
    component: Me,
    meta: {
      tabbar: true,
      auth: true
    }
  },
  {
    name: 'profile',
    path: '/profile/:id',
    component: Profile,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'students',
    path: '/students',
    component: Students,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'invite',
    path: '/invite',
    component: Invite,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'service',
    path: '/service',
    component: Service,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'security',
    path: '/security',
    component: Security,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'recommendations',
    path: '/recommendations',
    component: Recommendations,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'settings',
    path: '/settings',
    component: Settings,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'studentProfile',
    path: '/student/profile/:id',
    component: StudentProfile,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'createStudent',
    path: '/createStudent',
    component: CreateStudent,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'coupons',
    path: '/coupons',
    component: Coupons,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'recharges',
    path: '/recharges',
    component: Recharges,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  {
    name: 'recharge',
    path: '/recharge',
    component: Recharge
  },
  {
    name: 'recharged',
    path: '/recharged/:rechargeId',
    component: Recharged
  },
  {
    name: 'consumes',
    path: '/consumes',
    component: Consumes,
    meta: {
      tabbar: false,
      auth: true
    }
  },
  { 
    path: '/', 
    redirect: '/home'
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next: NavigationGuardNext) => {
  if (store.getters.isAuth) {
    if (to.name === 'auth') {
      next({ name: 'home' })
    } else {
      next()
    }
  } else {
    if (to.meta.auth) {
      next({
        name: 'auth',
        params: {
          to: to.name?.toString()
        }
      })
    } else {
      next()
    }
  }
})

export default router