<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.consumes')" left-arrow @click-left="onClickLeft" />

  <!-- 列表 -->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list v-model:loading="loading" :finished="finished" :finished-text="t('list.text.finished')" v-model:error="error" :error-text="t('list.text.error')" @load="onLoad">
      <consume-card v-for="consume in consumes" :key="consume.id" :consume="consume"></consume-card>
    </van-list>
  </van-pull-refresh>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import consumeAPI from '../../api/consume'
import ConsumeCard from '../../components/consume/ConsumeCard.vue'
import { useStore } from '../../store'
import { Query, QueryImpl } from '../../types/common/query'
import { ConsumeFilters, ConsumeFiltersImpl } from '../../types/common/query/filters/consumeFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { ConsumeSearcherImpl } from '../../types/common/query/searcher/consumeSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Consume } from '../../types/consume'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()
const parentId = store.getters.id

// Query consume
const consumes = ref<Consume[]>([])

const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ConsumeFilters>(new ConsumeFiltersImpl(parentId))
const searcher = reactive<Searcher>(new ConsumeSearcherImpl())
const sorts = [new SortImpl('dateTime', Direction.DESC)]

const finished = ref<boolean>(false)
const error = ref<boolean>(false)
const loading = ref<boolean>(false)
const doListConsume = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  consumeAPI.list(query).then((response: AxiosResponse<Result<ListObject<Consume>>>) => {
    if (refreshing.value) {
      refreshing.value = false
      consumes.value = []
    }

    const result = response.data
    if (result.success) {
      const listObject = result.data
      listObject.objects.forEach(consume => consumes.value?.push(consume))
      
      pagination.currentPage = pagination.currentPage + 1
      pagination.total = listObject.total

      if (consumes.value?.length! >= pagination.total) finished.value = true
    } else {
      showFailToast(result.message)
      error.value = true
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onLoad = function () {
  doListConsume()
}

const refreshing = ref<boolean>(false)
const onRefresh = function () {
  finished.value = false
  loading.value = true
  pagination.reset()
  onLoad()
}
</script>
