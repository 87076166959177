import { DropdownItemOption, PickerOption } from "vant"
import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Accent, Country, Gender } from "../../enums"

const vueI18n = i18n.global

export class GenderOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('gender.unknown'), Gender.UNKNOWN),
    new OptionImpl(vueI18n.t('gender.boy'), Gender.BOY),
		new OptionImpl(vueI18n.t('gender.girl'), Gender.GIRL),
	]

	static pickerOptions: PickerOption[] = [
		{text: vueI18n.t('gender.unknown'), value: Gender.UNKNOWN},
		{text: vueI18n.t('gender.boy'), value: Gender.BOY},
		{text: vueI18n.t('gender.girl'), value: Gender.GIRL},
	]
}

export class CountryOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('country.all'), Country.ALL),
		new OptionImpl(vueI18n.t('country.us'), Country.US),
		new OptionImpl(vueI18n.t('country.uk'), Country.UK),
		new OptionImpl(vueI18n.t('country.canada'), Country.CANADA)
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('country.all'), value: Country.ALL},
		{text: vueI18n.t('country.us'), value: Country.US},
		{text: vueI18n.t('country.uk'), value: Country.UK},
		{text: vueI18n.t('country.canada'), value: Country.CANADA}
	]
}

export class AccentOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('accent.all'), Accent.ALL),
		new OptionImpl(vueI18n.t('accent.us'), Accent.AMERICAN),
		new OptionImpl(vueI18n.t('accent.uk'), Accent.BRITISH)
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('accent.all'), value: Accent.ALL},
		{text: vueI18n.t('accent.us'), value: Accent.AMERICAN},
		{text: vueI18n.t('accent.uk'), value: Accent.BRITISH}
	]
}