<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.students')" left-arrow @click-left="onClickLeft" />

  <!-- 学生列表 -->
  <van-cell-group v-for="student in students" :key="student.id" inset class="mt-4">
    <student-card :student="student"></student-card>
  </van-cell-group>

  <!-- Button -->
  <div class="mx-4 my-4">
    <van-button round block type="primary" @click="doCreateStudent()">{{ t('button.create-student') }}</van-button>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import studentAPI from '../../api/student'
import StudentCard from '../../components/student/StudentCard.vue'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Student } from '../../types/student'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()
const parentId = store.getters.id

// 查找学生
const students = ref<Student[]>()
const doFindStudents = function () {
  studentAPI.findByParentId(parentId).then((response: AxiosResponse<Result<Student[]>>) => {
    const result = response.data
    if (result.success) {
      students.value = result.data
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const doCreateStudent = function () {
  router.push({name: 'createStudent'})
}

onMounted(() => {
  doFindStudents()
})
</script>
