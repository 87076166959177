<template>
  <div :style="style"></div>
</template>

<script setup lang="ts">

const props = defineProps<{
  height?: string
}>()

const style = {
  minHeight: props.height? props.height : '60px'
}
</script>
