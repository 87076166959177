<template>
  <van-cell-group inset>
    <van-cell :title="t('label.course.serie')" :value="course.serieName" />
    <van-cell :title="t('label.course.name')" :value="course.name" />
    <van-cell :title="t('label.course.type')" :value="CourseFilter.type(course.type)" />
    <van-cell :title="t('label.course.lesson-duration')" :value="CourseFilter.lessonDuration(course.lessonDuration)" />
    <van-cell :title="t('label.course.lesson-count')" :value="CourseFilter.lessonCount(course.lessonCount)" />
  </van-cell-group>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { CourseFilter } from '../../filters/courseFilter';
import { Course } from '../../types/course';

const props = defineProps<{
  course: Course
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>
