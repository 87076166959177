<template>
  <van-cell-group inset class="mt-4">
    <van-cell :title="t('table.arrangement.status')" :value="ArrangementFilter.status(arrangement.status)"></van-cell>
    <van-cell v-if="arrangement.status === Status.CANCELED" :title="t('table.arrangement.cancel-reason')" :value="ArrangementFilter.cancelReason(arrangement.cancelReason)"></van-cell>
    <van-cell :title="t('table.arrangement.time')" :value="DateTimeFilter.datetime(arrangement.startDateTime)"></van-cell>
    <van-cell :title="t('table.arrangement.duration')" :value="CourseFilter.lessonDuration(arrangement.courseLessonDuration)"></van-cell>
    <van-cell :title="t('table.arrangement.student')" :value="arrangement.studentNickname"></van-cell>
    <van-cell :title="t('table.arrangement.teacher')" :value="arrangement.teacherNickname"></van-cell>
    <van-cell :title="t('table.arrangement.course')" :value="arrangement.courseFullName"></van-cell>
    <van-cell :title="t('table.arrangement.lesson')" :value="arrangement.lessonFullName"></van-cell>
    <van-cell :title="t('table.arrangement.type')" :value="CourseFilter.kind(arrangement.courseType, arrangement.trial)"></van-cell>
    <van-cell v-if="arrangement.status === Status.FINISHED" :title="t('table.arrangement.comment')" :label="arrangement.remark">
      <template #value>
        <van-rate v-model="arrangement.star" :size="15" color="#ffd21e" void-icon="star" void-color="#eee"/>
      </template>
    </van-cell>
    <van-cell v-if="arrangement.reviewed" :title="t('table.arrangement.review')" :label="arrangement.feedback">
      <template #value>
        <van-rate v-model="arrangement.rank" :size="15" color="#ffd21e" void-icon="star" void-color="#eee"/>
      </template>
    </van-cell>
  </van-cell-group>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { Arrangement, Status } from '../../types/arrangement'

const props = defineProps<{
  arrangement: Arrangement
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>