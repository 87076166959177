<template>
  <van-popup v-model:show="showPopup" round position="bottom">
    <van-picker :title="t('label.cancel-reason')" :columns="ArrangementCancelReasonOptions.pickerOptions" :cancel-button-text="t('button.close')" @cancel="onCancel" @confirm="onConfirm" />
  </van-popup>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { PickerConfirmEventParams, showFailToast, showSuccessToast } from 'vant'
import { reactive, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import arrangementAPI from '../../api/arrangement'
import { Arrangement, CancelArrangement, CancelArrangementImpl, CancelReason } from '../../types/arrangement'
import { ArrangementCancelReasonOptions } from '../../types/common/option/arrangementOptions'
import { Result } from '../../types/common/result'
import { useModelWrapper } from '../../utils/modelWrapper'

const props = defineProps<{
  arrangementId: string,
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'update:show'): void,
  (e: 'success'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const showPopup = useModelWrapper(props, emit, 'show')

const cancelArrangement = reactive<CancelArrangement>(new CancelArrangementImpl(props.arrangementId))

const onCancel = function () {
  showPopup.value = false
}

const canceling = ref<boolean>(false)
const onConfirm = function (event: PickerConfirmEventParams) {
  cancelArrangement.cancelReason = event.selectedOptions[0]?.value as CancelReason

  canceling.value = true
  arrangementAPI.cancel(cancelArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
    const result = response.data
    if (result.success) {
      showSuccessToast(t('message.cancel-arrangement-success'))
      showPopup.value = false
      emit('success')
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    canceling.value = false
  })
}

watch(() => props.arrangementId, (arrangementId) => {
  cancelArrangement.id = arrangementId
})
</script>
