<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.supplement')" :right-text="t('button.logout')" @click-right="onClickRight" />

  <create-student @success="onCreateStudentSuccess"></create-student>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import CreateStudent from '../components/supplement/CreateStudent.vue'
import { Actions, useStore } from '../store'

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()
const router = useRouter()

const onCreateStudentSuccess = function() {
    router.push({
      name: 'welcome'
    })
}

const onClickRight = function() {
  doLogout()
}

// 退出登录
const doLogout = function() {
  store.dispatch(Actions.LOGOUT).then(() => {
    router.push({	name: 'home' })
  })
}
</script>
