<template>
  <router-link :to="{name: 'teacher', params: {id: teacher.id, enrollmentId: enrollmentId}}">
    <div class="my-2 d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center ms-4 text-color">
        <van-image :src="teacher.avatar" width="80" height="80" round class="bg-white"></van-image>
        <div class="ms-4">
          <div class="d-flex align-items-center">
            <span>{{ teacher.nickname }}</span><van-tag class="ms-2">{{ TeacherFilter.level(teacher.level) }}</van-tag>
          </div>
          <van-rate class="my-1" v-model="teacher.rank" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" allow-half />
          <div class="text-md">{{ EnumFilter.country(teacher.displayCountry) }} | {{ EnumFilter.accent(teacher.accent) }} | ${{ NumberFilter.money(teacher.fee) }} / {{ t('unit.lesson') }}</div>
        </div>
      </div>
      <van-icon name="arrow" class="me-4" />
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { EnumFilter } from '../../filters/enumFilter'
import { NumberFilter } from '../../filters/numberFilter'
import { TeacherFilter } from '../../filters/teacherFilter'
import { Teacher } from '../../types/teacher'

const props = defineProps<{
  teacher: Teacher,
  enrollmentId: string
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>
