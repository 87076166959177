<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.coupons')" left-arrow @click-left="onClickLeft" />

  <!-- 登录 -->
  <van-tabs v-model:active="status" @click-tab="onStatusChange">
    <van-tab v-for="option in AcquiredCouponStatusOptions.options" :title="option.label" :name="option.value?.toString()">
      <!-- 列表 -->
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list v-model:loading="loading" :finished="finished" :finished-text="t('list.text.finished')" v-model:error="error" :error-text="t('list.text.error')" @load="onLoad">
          <acquired-coupon-card v-for="acquiredCoupon in acquiredCoupons" :key="acquiredCoupon.id" :acquiredCoupon="acquiredCoupon"></acquired-coupon-card>
        </van-list>
      </van-pull-refresh>
    </van-tab>
  </van-tabs>


</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios';
import { showFailToast } from 'vant';
import { reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import acquiredCouponAPI from '../../api/acquiredCoupon';
import AcquiredCouponCard from '../../components/coupon/AcquiredCouponCard.vue';
import { useStore } from '../../store';
import { AcquiredCoupon, Status } from '../../types/acquiredCoupon';
import { AcquiredCouponStatusOptions } from '../../types/common/option/acquiredCouponOptions';
import { QueryImpl } from '../../types/common/query';
import { AcquiredCouponFilters, AcquiredCouponFiltersImpl } from '../../types/common/query/filters/acquiredCouponFilters';
import { Pagination, PaginationImpl } from '../../types/common/query/pagination';
import { Searcher } from '../../types/common/query/searcher';
import { AcquiredCouponSearcherImpl } from '../../types/common/query/searcher/acquiredCouponSearcher';
import { Direction, SortImpl } from '../../types/common/query/sort';
import { ListObject, Result } from '../../types/common/result';

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()
const parentId = store.getters.id

const status = ref<Status>(Status.ACQUIRED)

// Query acquiredCoupon
const acquiredCoupons = ref<AcquiredCoupon[]>([])

const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<AcquiredCouponFilters>(new AcquiredCouponFiltersImpl(parentId))
const searcher = reactive<Searcher>(new AcquiredCouponSearcherImpl())
const sorts = [new SortImpl('acquiredDate', Direction.DESC)]

const finished = ref<boolean>(false)
const error = ref<boolean>(false)
const loading = ref<boolean>(false)
const doListAcquiredCoupon = function () {
  const query = new QueryImpl(pagination, filters, searcher, sorts)
  query.filters.status = status.value
  loading.value = true
  acquiredCouponAPI.list(query).then((response: AxiosResponse<Result<ListObject<AcquiredCoupon>>>) => {
    if (refreshing.value) {
      refreshing.value = false
      acquiredCoupons.value = []
    }

    const result = response.data
    if (result.success) {
      const listObject = result.data
      listObject.objects.forEach(object => acquiredCoupons.value?.push(object))
      
      pagination.currentPage = pagination.currentPage + 1
      pagination.total = listObject.total

      if (acquiredCoupons.value?.length! >= pagination.total) finished.value = true
    } else {
      showFailToast(result.message)
      error.value = true
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onStatusChange = function () {
  refreshing.value = true
  pagination.reset()
  doListAcquiredCoupon()
}

const onLoad = function () {
  doListAcquiredCoupon()
}

const refreshing = ref<boolean>(false)
const onRefresh = function () {
  finished.value = false
  loading.value = true
  pagination.reset()
  onLoad()
}

</script>