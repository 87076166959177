<template>
  <div class="alert" :class="[kindClass]">
    <div class="mx-4">
      <h3>{{ title }}</h3>
      <p>{{ description }}</p>
    </div>
    
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Kind = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | undefined

const props = defineProps<{
  kind: Kind,
  title: string,
  description: string
}>()

const kindClass = computed(() => {
  if (props.kind) return 'alert-' + props.kind
  else return ''
})
</script>
