<template>
  <div class="mx-4 mt-4 card p-4" :class="[backgroundClass, textClass]" @click="onSelect">
    <div class="d-flex justify-content-between">
        <div class="text-lg">{{ rechargeCard.lessons }}{{ t('unit.lessons') }}</div>
        <div v-if="rechargeCard.hot" class="text-md hot" :class="[textClass]">{{ t('recharge-card.hot') }}</div>
    </div>
    <div class="mt-4 d-flex justify-content-between">
      <div class="d-flex align-items-baseline">
        <div>${{ NumberFilter.money(rechargeCard.discountPrice) }}</div>
        <div class="ms-2 text-line-through text-gray-4 text-md">${{ NumberFilter.money(rechargeCard.price) }}</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { NumberFilter } from '../../filters/numberFilter'
import { RechargeCard } from '../../types/rechargeCard'

const props = defineProps<{
  rechargeCard: RechargeCard,
  selectedRechargeCard: RechargeCard
}>()

const emit = defineEmits<{
  (e: 'select', selectedRechargeCard: RechargeCard): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const backgroundClass = computed(() => {
  if (props.rechargeCard.id === props.selectedRechargeCard.id) return 'bg-primary'
  else return ''
})

const textClass = computed(() => {
  if (props.rechargeCard.id === props.selectedRechargeCard.id) return 'text-white'
  else return ''
})

const onSelect = function () {
  emit('select', props.rechargeCard)
}
</script>

<style>
.card {
  background-color: white;
  border-radius: 0.5rem;
}
</style>