<template>
  <!-- Form -->
  <van-form ref="updateStudentInfoForm">
    <van-cell-group inset>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger">{{ message }}</div>
      <!-- Nickname -->
      <van-field v-model.trim="updateStudentInfo.nickname" name="nickname" :label="t('label.nickname')" input-align="right" clearable :rules="[{ validator: mustInputValidator, trigger: 'onBlur' }]"></van-field>
      <!-- Name -->
      <van-field v-model.trim="updateStudentInfo.name" name="name" :label="t('label.name')" input-align="right" clearable :rules="[{ validator: mustInputValidator, trigger: 'onBlur' }]"></van-field>
      <!-- Gender -->
      <it-picker input-align="right" :label="t('label.gender')" :value="EnumFilter.gender(updateStudentInfo.gender)" :options="GenderOptions.pickerOptions" @confirm="onGenderPickerConfirm"></it-picker>
      <!-- Birthday -->
      <it-date-picker :label="t('label.birthday')" :min-date="minBirthday" :max-date="maxBirthday" :value="DateTimeFilter.date(updateStudentInfo.birthday)" @confirm="onBirthdayPickerConfirm"></it-date-picker>
    </van-cell-group>

    <!-- Button -->
    <div class="mx-4 my-4">
      <van-button round block type="primary" :loading="updating" @click="doUpdateStudentInfo">{{ t('button.update') }}</van-button>
    </div>
  </van-form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { FormInstance, PickerOption, showFailToast, showSuccessToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import studentAPI from '../../api/student'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { EnumFilter } from '../../filters/enumFilter'
import { useStore } from '../../store'
import { GenderOptions } from '../../types/common/option/enumOptions'
import { Result } from '../../types/common/result'
import { Gender } from '../../types/enums'
import { Student, UpdateStudentInfo, UpdateStudentInfoImpl } from '../../types/student'
import { getMaxBirthday, getMinBirthday, parseLocalDateTime } from '../../utils/dateTimeUtils'
import { mustInputValidator } from '../../validators'
import ItDatePicker from '../ui/ItDatePicker.vue'
import ItPicker from '../ui/ItPicker.vue'

const props = defineProps<{
  student: Student
}>()

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()

const message = ref<string>('')

const minBirthday = getMinBirthday()
const maxBirthday = getMaxBirthday()

const updateStudentInfo = reactive<UpdateStudentInfo>(new UpdateStudentInfoImpl(props.student))
const updateStudentInfoForm = ref<FormInstance>()

const updating = ref<boolean>(false)
const doUpdateStudentInfo = function () {
  updateStudentInfoForm.value?.validate().then(() => {
    updating.value = true
    studentAPI.updateInfo(updateStudentInfo).then((response: AxiosResponse<Result<Student>>) => {
      const result = response.data
      if (result.success) {
        showSuccessToast(t('message.update-student-info-success'))
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      showFailToast(error.message)
    }).finally(() => {
      updating.value = false
    })
  })
}

const onGenderPickerConfirm = function (selectedOptions: (PickerOption | undefined)[]) {
  updateStudentInfo.gender = selectedOptions[0]?.value as Gender
}

const onBirthdayPickerConfirm = function (selectedOptions: (PickerOption | undefined)[]) {
  const year = selectedOptions[0]?.value as string
  const month = selectedOptions[1]?.value as string
  const day = selectedOptions[2]?.value as string
  updateStudentInfo.birthday = parseLocalDateTime(year, month, day).toJSDate()
}

const refresh = function (student: Student) {
  updateStudentInfo.refresh(student)
}

defineExpose({
  refresh
})
</script>
