import { DropdownItemOption, PickerOption } from "vant"
import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { CancelReason, Status } from "../../arrangement"

const vueI18n = i18n.global

export class ArrangementCancelReasonOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.all'), CancelReason.ALL),
    new OptionImpl(vueI18n.t('arrangement.cancel-reason.leave-student'), CancelReason.LEAVE_STUDENT),
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.leave-teacher'), CancelReason.LEAVE_TEACHER),
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.absent-student'), CancelReason.ABSENT_STUDENT),
    new OptionImpl(vueI18n.t('arrangement.cancel-reason.absent-teacher'), CancelReason.ABSENT_TEACHER),
		new OptionImpl(vueI18n.t('arrangement.cancel-reason.others'), CancelReason.OTHERS)
	]

	static pickerOptions: PickerOption[] = [
		{text: vueI18n.t('arrangement.cancel-reason.leave-student'), value: CancelReason.LEAVE_STUDENT},
		{text: vueI18n.t('arrangement.cancel-reason.absent-teacher'), value: CancelReason.ABSENT_TEACHER},
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('arrangement.cancel-reason.all'), value: CancelReason.ALL},
		{text: vueI18n.t('arrangement.cancel-reason.leave-student'), value: CancelReason.LEAVE_STUDENT},
		{text: vueI18n.t('arrangement.cancel-reason.leave-teacher'), value: CancelReason.LEAVE_TEACHER},
		{text: vueI18n.t('arrangement.cancel-reason.absent-student'), value: CancelReason.ABSENT_STUDENT},
		{text: vueI18n.t('arrangement.cancel-reason.absent-teacher'), value: CancelReason.ABSENT_TEACHER},
		{text: vueI18n.t('arrangement.cancel-reason.others'), value: CancelReason.OTHERS}
	]
}

export class ArrangementStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('arrangement.status.all'), Status.ALL),
    new OptionImpl(vueI18n.t('arrangement.status.booked'), Status.BOOKED),
		new OptionImpl(vueI18n.t('arrangement.status.finished'), Status.FINISHED),
		new OptionImpl(vueI18n.t('arrangement.status.canceled'), Status.CANCELED),
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('arrangement.status.all'), value: Status.ALL},
		{text: vueI18n.t('arrangement.status.booked'), value: Status.BOOKED},
		{text: vueI18n.t('arrangement.status.finished'), value: Status.FINISHED},
		{text: vueI18n.t('arrangement.status.canceled'), value: Status.CANCELED}
	]
}