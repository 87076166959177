import { DropdownItemOption } from "vant"
import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Status } from "../../enrollment"

const vueI18n = i18n.global

export class EnrollmentStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('enrollment.status.all'), Status.ALL),
    new OptionImpl(vueI18n.t('enrollment.status.created'), Status.CREATED),
		new OptionImpl(vueI18n.t('enrollment.status.started'), Status.STARTED),
		new OptionImpl(vueI18n.t('enrollment.status.suspended'), Status.SUSPENDED),
		new OptionImpl(vueI18n.t('enrollment.status.finished'), Status.FINISHED),
		new OptionImpl(vueI18n.t('enrollment.status.canceled'), Status.CANCELED),
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('enrollment.status.all'), value: Status.ALL},
		{text: vueI18n.t('enrollment.status.created'), value: Status.CREATED},
		{text: vueI18n.t('enrollment.status.started'), value: Status.STARTED},
		{text: vueI18n.t('enrollment.status.suspended'), value: Status.SUSPENDED},
		{text: vueI18n.t('enrollment.status.finished'), value: Status.FINISHED},
		{text: vueI18n.t('enrollment.status.canceled'), value: Status.CANCELED}
	]
}