import { library } from '@fortawesome/fontawesome-svg-core'
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { Integrations } from '@sentry/tracing'
import * as Sentry from '@sentry/vue'
import '@vant/touch-emulator'
import { inject } from '@vercel/analytics'
import { createHead } from "@vueuse/head"
import { ActionBar, ActionBarButton, ActionBarIcon, ActionSheet, Button, Card, Cell, CellGroup, ConfigProvider, CountDown, CouponCell, CouponList, DatePicker, Dialog, Divider, DropdownItem, DropdownMenu, Empty, Field, Form, Grid, GridItem, Icon, List, Loading, NavBar, NoticeBar, Picker, Popup, PullRefresh, Rate, Sidebar, SidebarItem, Step, Steps, Sticky, Swipe, SwipeItem, Tab, Tabbar, TabbarItem, Tabs, Tag, Toast, Image as VanImage } from 'vant'
import 'vant/lib/index.css'
import { createApp } from 'vue'
import config from '../package.json'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import { key, store } from './store'

inject()

const app = createApp(App)

const environment = import.meta.env.VITE_ENVIRONMENT as string
if (environment !== 'dev') {
  switch(import.meta.env.VITE_APP_BRAND) {
    case 'CetuSchool': {
      Sentry.init({
        app,
        dsn: 'https://c66e51d2c9554e738fd73e3540dc0bb7@o1110879.ingest.sentry.io/6361535',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'mobile-parent@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['parent.staging.cetuschool.com', 'parent.cetuschool.com', /^\//],
          })
        ]
      })
      break
    }
    case 'DDTalk': {
      Sentry.init({
        app,
        dsn: 'https://80ed45c49a7d4545b28639e97c90e443@o4504817848549376.ingest.sentry.io/4504829131358208',
        // This sets the sample rate to be 10%. You may want this to be 100% while in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
        release: 'mobile-parent@' + config.version,
        environment: environment,
        integrations: [
          new Sentry.Replay(),
          new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['parent.staging.ddtalk.me', 'parent.ddtalk.me', /^\//],
          })
        ]
      })
      break
    }
  }
}

let icon = ''
let theme = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    icon = '/icon/cetuschool.ico'
    theme = '/css/theme/cetuschool.css'
    break
  }
  case 'DDTalk': {
    icon = '/icon/ddtalk.ico'
    theme = '/css/theme/ddtalk.css'
    break
  }
}
const head = createHead({
  title: import.meta.env.VITE_APP_TITLE,
  link: [
    {
      rel: 'icon',
      href: icon
    },
    {
      rel: 'stylesheet',
      type: 'text/css',
      href: theme
    },
  ]
})

library.add(faGraduationCap)

app.use(head)
  .use(i18n)
  .use(router)
  .use(store, key)
  .use(VanImage)
  .use(Icon)
  .use(Empty)
  .use(Toast)
  .use(Button)
  .use(NavBar)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Sidebar)
  .use(SidebarItem)
  .use(Tab)
  .use(Tabs)
  .use(Grid)
  .use(GridItem)
  .use(Form)
  .use(Field)
  .use(Cell)
  .use(CellGroup)
  .use(CountDown)
  .use(Picker)
  .use(DatePicker)
  .use(Popup)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(List)
  .use(PullRefresh)
  .use(Step)
  .use(Steps)
  .use(Sticky)
  .use(ActionBarIcon)
  .use(ActionBarButton)
  .use(ActionBar)
  .use(ActionSheet)
  .use(Rate)
  .use(Tag)
  .use(NoticeBar)
  .use(Dialog)
  .use(Divider)
  .use(Swipe)
  .use(SwipeItem)
  .use(Loading)
  .use(Card)
  .use(ConfigProvider)
  .use(CouponCell)
  .use(CouponList)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')
