import { Result } from "../types/common/result"
import { CourseSerie, Serie, Subject } from "../types/serie"
import service from "./api-service"

const api = '/serie'

const serieAPI = {
  findAllVisible () {
    return service.get<Result<CourseSerie[]>>(api + '/findAllVisible')
  },
  
  findVisibleBySubject (subject: Subject) {
    return service.get<Result<CourseSerie[]>>(api + '/findVisibleBySubject', { 
      params: {
        subject: subject
      }
    })
  },

  findById (id: string) {
    return service.get<Result<Serie>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },
}

export default serieAPI