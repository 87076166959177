<template>
  <div>
    <van-cell-group v-for="module in modules" :key="module.id" :title="module.name" inset>
      <van-cell v-for="lesson in module.lessons" :key="lesson.id" :title="lesson.shortName"></van-cell>
    </van-cell-group>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, ref } from 'vue'
import moduleAPI from '../../api/module'
import { Result } from '../../types/common/result'
import { DetailModule } from '../../types/module'

const props = defineProps<{
  courseId: string
}>()

const loading = ref<boolean>(false)

const modules = ref<DetailModule[]>()
const doFindModule = function () {
  moduleAPI.findDetailByCourseId(props.courseId).then((response: AxiosResponse<Result<DetailModule[]>>) => {
    const result = response.data
    if (result.success) {
      modules.value = result.data
    } else {
      showFailToast(result.message)
    }						
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

onMounted(() => {
  doFindModule()
})
</script>