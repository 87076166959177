<template>
  <van-cell-group inset class="mt-4">
    <van-cell :title="t('table.enrollment.student-nickname')" :value="enrollment.studentNickname"></van-cell>
    <van-cell :title="t('table.enrollment.course-name')" :value="enrollment.courseFullName"></van-cell>
    <van-cell :title="t('table.enrollment.course-type')" :value="CourseFilter.type(enrollment.courseType)"></van-cell>
    <van-cell :title="t('table.enrollment.status')" :value="EnrollmentFilter.status(enrollment.status)"></van-cell>
    <van-cell :title="t('table.enrollment.arranges')">
      <template #value>
        <div v-if="enrollment.arranges && enrollment.arranges.length > 0">
          <div v-for="(arrange, index) in enrollment.arranges" :key="index">
            {{ ArrangeFilter.arrange(arrange) }}
          </div>
        </div>
        <div v-else>
          {{ t('empty.arranges') }}
        </div>
      </template>
    </van-cell>
    <!-- TODO 打开自主报名 -->
    <!-- <div class="mx-2 my-2 d-flex flex-row justify-content-end">
      <van-button size="small" type="primary" v-if="enrollment.status === Status.CREATED" @click="doGotoArrange(enrollment)">{{ t('button.start') }}</van-button>
      <van-button size="small" type="primary" class="ms-2" v-if="enrollment.status === Status.STARTED" @click="doRearrangeEnrollment(enrollment)">{{ t('button.rearrange') }}</van-button>
      <van-button size="small" type="primary" class="ms-2" v-if="enrollment.status === Status.STARTED" @click="doSuspendEnrollment(enrollment)">{{ t('button.suspend') }}</van-button>
      <van-button size="small" type="primary" class="ms-2" v-if="enrollment.status === Status.CREATED || enrollment.status === Status.STARTED" @click="doCancelEnrollment(enrollment)">{{ t('button.cancel') }}</van-button>
      <van-button size="small" type="primary" class="ms-2" v-if="enrollment.status === Status.CANCELED" @click="doRecreateEnrollment(enrollment)">{{ t('button.recreate') }}</van-button>
      <van-button size="small" type="primary" class="ms-2" v-if="enrollment.status === Status.SUSPENDED && enrollment.suspendReason === SuspendReason.LEAVE" @click="doGotoArrange(enrollment)">{{ t('button.restart') }}</van-button>
    </div> -->
  </van-cell-group>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast, showSuccessToast } from 'vant'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import enrollmentAPI from '../../api/enrollment'
import { ArrangeFilter } from '../../filters/arrangeFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { EnrollmentFilter } from '../../filters/enrollmentFilter'
import { Result } from '../../types/common/result'
import { CancelEnrollmentImpl, Enrollment, RearrangeEnrollmentImpl, RecreateEnrollmentImpl, SuspendEnrollmentImpl } from '../../types/enrollment'

const props = defineProps<{
  enrollment: Enrollment
}>()

const emit = defineEmits<{
  (e: 'suspended'): void,
  (e: 'canceled'): void
}>()

const { t } = useI18n({
  useScope: 'global'
})

const router = useRouter()
const doGotoArrange = function (enrollment: Enrollment) {
  router.push({
    name: 'arrange',
    params: {
      enrollmentId: enrollment.id
    }
  })
}

// Suspend Enrollment
const doSuspendEnrollment = function(enrollment: Enrollment) {
  const suspendEnrollment = new SuspendEnrollmentImpl(enrollment.id)
  enrollmentAPI.suspend(suspendEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      showSuccessToast(t('message.suspend-enrollment-success'))
      emit('suspended')
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// Cancel Enrollment
const doCancelEnrollment = function(enrollment: Enrollment) {
  const cancelEnrollment = new CancelEnrollmentImpl(enrollment.id)
  enrollmentAPI.cancel(cancelEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      showSuccessToast(t('message.cancel-enrollment-success'))
      emit('canceled')
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// Recreate Enrollment
const doRecreateEnrollment = function(enrollment: Enrollment) {
  const recreateEnrollment = new RecreateEnrollmentImpl(enrollment.id)
  enrollmentAPI.recreate(recreateEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      doGotoArrange(enrollment)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// Rearrange Enrollment
const doRearrangeEnrollment = function(enrollment: Enrollment) {
  const rearrangeEnrollment = new RearrangeEnrollmentImpl(enrollment.id)
  enrollmentAPI.rearrange(rearrangeEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      doGotoArrange(enrollment)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}
</script>
