import i18n from "../i18n"
import { Method, Status, Type } from "../types/recharge"

const vueI18n = i18n.global

export class RechargeFilter {
  static status (status?: Status): string {
    switch (status) {
      case Status.CREATED:
        return vueI18n.t('recharge.status.created')
      case Status.COMPLETED:
        return vueI18n.t('recharge.status.completed')
      case Status.VOIDED:
        return vueI18n.t('recharge.status.voided')
      default:
        return '' 
    }
  }

  static type (type?: Type): string {
    switch (type) {
      case Type.USER:
        return vueI18n.t('recharge.type.user')
      case Type.SYSTEM:
        return vueI18n.t('recharge.type.system')
      case Type.STAFF:
        return vueI18n.t('recharge.type.staff')
      default:
        return '' 
    }
  }

  static method (method?: Method): string {
    switch (method) {
      case Method.CARD:
        return vueI18n.t('recharge.method.card')
      case Method.ALIPAY:
        return vueI18n.t('recharge.method.alipay')
      case Method.WECHAT_PAY:
        return vueI18n.t('recharge.method.wechat-pay')
      case Method.CASH:
        return vueI18n.t('recharge.method.cash')
      case Method.OTHERS:
        return vueI18n.t('recharge.method.others')
      default:
        return '' 
    }
  }
}