<template>
  <it-picker input-align="right" :label="t('label.locale')" :value="LocaleFilter.locale(updateParentLocale.locale)" :options="pickerOptions" @confirm="onConfirm"></it-picker>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { PickerOption, showFailToast, showSuccessToast } from 'vant'
import { reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import { LocaleFilter } from '../../filters/localeFilter'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentLocale, UpdateParentLocaleImpl } from '../../types/parent'
import ItPicker from '../ui/ItPicker.vue'

const props = defineProps<{
  parentId: string
}>()

const { t, locale, availableLocales } = useI18n({
  useScope: 'global'
})

const store = useStore()

const doConvertLocales = function (locales: object[]): PickerOption[] {
  let pickerOptions: PickerOption[] = []
  locales.forEach(locale => {
    let value = locale as unknown as string
    pickerOptions.push({ text: LocaleFilter.locale(locale), value: value })
  })
  return pickerOptions
}

const pickerOptions = doConvertLocales(availableLocales)

const onConfirm = function(selectedOptions: (PickerOption | undefined)[]) {
  updateParentLocale.locale = selectedOptions[0]?.value as string
  locale.value = updateParentLocale.locale
  doUpdateParentLocale()
}

// 更新语言
const updateParentLocale = reactive<UpdateParentLocale>(new UpdateParentLocaleImpl(props.parentId))
const doUpdateParentLocale = function () {
  parentAPI.updateLocale(updateParentLocale).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      const parent: Parent = result.data
      store.dispatch(Actions.CHANGE_LOCALE, parent.locale)
      showSuccessToast(t('message.set-locale-success'))
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}
</script>
