import { Status } from "../../../recommendation"
import { Filters } from "./index"

export interface RecommendationFilters extends Filters {
	referrerId: string
	status: Status
}

export class RecommendationFiltersImpl implements RecommendationFilters {
	referrerId: string
	status: Status

	constructor(referrerId: string) {
		this.referrerId = referrerId
		this.status = Status.ALL
	}

	reset(): void {
		this.status = Status.ALL
	}
}