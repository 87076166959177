export class LocaleFilter {
    static locale (locale: unknown): string {
        switch (locale) {
            case 'en': return 'English'
            case 'en-US': return 'English - US'
            case 'zh-CN': return '中文 - 简体'
            case 'zh-TW': return '中文 - 繁體'
            default: return 'Not Set'
        }
    }
}