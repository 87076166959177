import { ParentAuthed, ParentEmailOtpAuth, ParentMobileOtpAuth } from "../types/auth"
import { Result } from "../types/common/result"
import service from "./auth-service"

const api = '/parent/auth'

const authAPI = {
  emailOtpAuth (parentEmailOtpAuth: ParentEmailOtpAuth) {
    return service.post<Result<ParentAuthed>>(api + '/email-otp', parentEmailOtpAuth)
  },

  mobileOtpAuth (parentMobileOtpAuth: ParentMobileOtpAuth) {
    return service.post<Result<ParentAuthed>>(api + '/mobile-otp', parentMobileOtpAuth)
  }
}

export default authAPI