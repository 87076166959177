import { SerieCourse } from "./course"

export enum Subject {
	ALL = 'ALL',
	ENGLISH = 'ENGLISH',
	MATH = 'MATH'
}

export interface Serie {
	id: string
	name: string
	subject: Subject
	major: boolean
}

export class SerieImpl implements Serie {
	id: string = ''
	name: string = ''
	subject: Subject = Subject.ENGLISH
	major: boolean = false
}

export interface CourseSerie {
	id: string
	name: string
	subject: Subject
	major: boolean
	courses: [SerieCourse]
}