
<template>
  <van-config-provider :theme="store.getters.theme">
    <router-view />

    <it-placeholder v-if="route.meta.tabbar"></it-placeholder>

    <van-tabbar route v-if="route.meta.tabbar">
      <van-tabbar-item replace to="/home" icon="home-o">{{ t('tabbar.home') }}</van-tabbar-item>
      <van-tabbar-item replace to="/courses" icon="apps-o">{{ t('tabbar.courses') }}</van-tabbar-item>
      <van-tabbar-item replace to="/schedule" icon="todo-list-o">{{ t('tabbar.learning') }}</van-tabbar-item>
      <van-tabbar-item replace to="/me" icon="contact">{{ t('tabbar.me') }}</van-tabbar-item>
    </van-tabbar>
  </van-config-provider>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import ItPlaceholder from './components/ui/ItPlaceholder.vue';
import { useStore } from './store';

const { t } = useI18n({
  useScope: "global"
})

const store = useStore()
const route = useRoute()
</script>
