<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.recommendations')" left-arrow @click-left="onClickLeft" />

  <!-- 筛选栏 -->
  <van-dropdown-menu>
    <van-dropdown-item v-model="filters.status" :options="RecommendationStatusOptions.dropdownItemOptions" @change="onStatusChange" />
  </van-dropdown-menu>

  <!-- 列表 -->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list v-model:loading="loading" :finished="finished" :finished-text="t('list.text.finished')" v-model:error="error" :error-text="t('list.text.error')" @load="onLoad">
      <recommendation-card v-for="recommendation in recommendations" :key="recommendation.id" :recommendation="recommendation"></recommendation-card>
    </van-list>
  </van-pull-refresh>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import recommendationAPI from '../../api/recommendation'
import RecommendationCard from '../../components/recommendation/RecommendationCard.vue'
import { useStore } from '../../store'
import { RecommendationStatusOptions } from '../../types/common/option/recommendationOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { RecommendationFilters, RecommendationFiltersImpl } from '../../types/common/query/filters/recommendationFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { RecommendationSearcherImpl } from '../../types/common/query/searcher/recommendationSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Recommendation } from '../../types/recommendation'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()
const parentId = store.getters.id

// Query recommendation
const recommendations = ref<Recommendation[]>([])

const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<RecommendationFilters>(new RecommendationFiltersImpl(parentId))
const searcher = reactive<Searcher>(new RecommendationSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const finished = ref<boolean>(false)
const error = ref<boolean>(false)
const loading = ref<boolean>(false)
const doListRecommendation = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  recommendationAPI.list(query).then((response: AxiosResponse<Result<ListObject<Recommendation>>>) => {
    if (refreshing.value) {
      refreshing.value = false
      recommendations.value = []
    }

    const result = response.data
    if (result.success) {
      const listObject = result.data
      listObject.objects.forEach(object => recommendations.value?.push(object))
      
      pagination.currentPage = pagination.currentPage + 1
      pagination.total = listObject.total

      if (recommendations.value?.length! >= pagination.total) finished.value = true
    } else {
      showFailToast(result.message)
      error.value = true
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onStatusChange = function () {
  refreshing.value = true
  pagination.reset()
  doListRecommendation()
}

const onLoad = function () {
  doListRecommendation()
}

const refreshing = ref<boolean>(false)
const onRefresh = function () {
  finished.value = false
  loading.value = true
  pagination.reset()
  onLoad()
}
</script>
