<template>
  <!-- Form -->
  <van-form ref="updateParentMobileForm">
    <van-cell-group inset>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger">{{ message }}</div>
      <!-- Country Code -->
      <it-picker input-align="right" :label="t('label.country-code')" :placeholder="t('placeholder.country-code')" :value="MobileFilter.region(updateParentMobile.region, phoneCountryCodes)" :options="pickerOptions" @confirm="onConfirm" :rules="[{ required: true, message: t('validator.invalidate-country-code') }]"></it-picker>
      <!-- Mobile -->
      <van-field v-model.trim="updateParentMobile.mobile" name="mobile" :label="t('label.mobile')" label-width="50" clearable :rules="[{ validator: mobileValidator, trigger: 'onBlur' }]"></van-field>
      <!-- OTP -->
      <van-field type="digit" v-model.trim="updateParentMobile.otp" name="otp" :label="t('label.otp')" label-width="50" clearable :rules="[{ validator: otpValidator, trigger: 'onBlur' }]">
        <template #button>
          <van-button size="small" type="primary" @click="doSendUpdateMobileOtp" style="width: 100px;" :loading="sending" :disabled="counting">
            <van-count-down v-if="counting" :time="60 * 1000" format="ss" @finish="counting = false" />
            <span v-else>{{ t('button.get-otp') }}</span>
          </van-button>
        </template>
      </van-field>
    </van-cell-group>

    <!-- Button -->
    <div class="mx-4 my-4">
      <van-button round block type="primary" :loading="updating" @click="doUpdateParentMobile">{{ t('button.update-mobile') }}</van-button>
    </div>
  </van-form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { FormInstance, PickerOption, showFailToast, showSuccessToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import mobileAPI from '../../api/mobile'
import parentAPI from '../../api/parent'
import smsAPI from '../../api/sms'
import { MobileFilter } from '../../filters/mobileFilter'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { PhoneCountryCode } from '../../types/mobile'
import { Parent, UpdateParentMobile, UpdateParentMobileImpl } from '../../types/parent'
import { getCodeFromRegion } from '../../utils/mobileUtils'
import { mobileValidator, otpValidator } from '../../validators'
import ItPicker from '../ui/ItPicker.vue'

const props = defineProps<{
  parent: Parent
}>()

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()

const phoneCountryCodes = ref<PhoneCountryCode[]>([])
const pickerOptions = ref<PickerOption[]>([])
const doConvertPhoneCountryCodes = function (phoneCountryCodes: PhoneCountryCode[]): PickerOption[] {
  let pickerOptions: PickerOption[] = []
  phoneCountryCodes.forEach(phoneCountryCode => {
    pickerOptions.push({ text: phoneCountryCode.country, value: phoneCountryCode.region })
  })
  return pickerOptions
}

const onConfirm = function(selectedOptions: (PickerOption | undefined)[]) {
  updateParentMobile.region = selectedOptions[0]?.value as string
  updateParentMobile.countryCode = getCodeFromRegion(phoneCountryCodes.value, updateParentMobile.region)
}

// 查找国家区号代码
const doFindAllPhoneCountryCodes = function () {
  mobileAPI.findAllCountryCode(store.getters.locale).then((response: AxiosResponse<Result<PhoneCountryCode[]>>) => {
    const result = response.data
    if (result.success) {
      phoneCountryCodes.value = result.data
      pickerOptions.value = doConvertPhoneCountryCodes(phoneCountryCodes.value)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const message = ref<string>('')
const updateParentMobile = reactive<UpdateParentMobile>(new UpdateParentMobileImpl(props.parent))
const updateParentMobileForm = ref<FormInstance>()

// 发送验证码
const counting = ref<boolean>(false)
const sending = ref<boolean>(false)
const doSendUpdateMobileOtp = function() {
  updateParentMobileForm.value?.validate(['countryCode', 'mobile']).then(() => {
    if (updateParentMobile.countryCode && updateParentMobile.mobile) {
      sending.value = true
      smsAPI.sendUpdateMobileOtp(updateParentMobile.countryCode, updateParentMobile.mobile).then((response: AxiosResponse<Result<void>>) => {
        const result = response.data
        if (result.success) {
          counting.value = true
          showSuccessToast(t('message.send-otp-success'))
        } else {
          message.value = result.message
        }
      }).catch((error: AxiosError) => {
        showFailToast(error.message)
      }).finally(() => {
        sending.value = false
      })
    }
  }).catch(() => {})
}

// 更新手机
const updating = ref<boolean>(false)
const doUpdateParentMobile = function () {
  updateParentMobileForm.value?.validate().then(() => {
    updating.value = true
    parentAPI.updateMobile(updateParentMobile).then((response: AxiosResponse<Result<Parent>>) => {
      const result = response.data
      if (result.success) {
        counting.value = false
        updateParentMobile.otp = ''
        showSuccessToast(t('message.update-parent-mobile-success'))
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      showFailToast(error.message)
    }).finally(() => {
      updating.value = false
    })
  }).catch(() => {})
}

const refresh = function (parent: Parent) {
  updateParentMobile.refresh(parent)
}

onMounted(() => {
  doFindAllPhoneCountryCodes()
})

defineExpose({
  refresh
})
</script>
