<template>
  <div class="info d-flex flex-column align-items-center text-white">
    <van-image :src="teacher.avatar" width="80" height="80" round class="bg-white mt-4"></van-image>
    <div class="mt-2">{{ teacher.nickname }}</div>
    <van-rate class="mt-2 mb-4" v-model="teacher.rank" :size="12" color="#ffd21e" void-icon="star" void-color="#eee" allow-half />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { Teacher } from '../../types/teacher'

const props = defineProps<{
  teacher: Teacher
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>

<style scoped>
.info {
  background-color: var(--van-primary-color);
}
</style>