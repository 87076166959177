import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

const config: AxiosRequestConfig = {
  baseURL: import.meta.env.VITE_API_URL as string,
  responseType: 'json',
	headers: { 
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json; charset=utf-8',
		'Client': 'Parent'
	},
}

const service: AxiosInstance = axios.create(config)

export default service