import { Result } from "../types/common/result";
import { TeachingTimeSlots, TimeSlot, ToggleTimeSlotBooked } from "../types/teachingTimeSlots";
import service from "./api-service";

const api = '/teachingTimeSlots'

const teachingTimeSlotsAPI = {
  findById (id: string) {
    return service.get<Result<TeachingTimeSlots>>(api + '/findById', { 
        params: {
            id: id
        }
    })
  },

  findByTeacherId (teacherId: string) {
    return service.get<Result<TeachingTimeSlots>>(api + '/findByTeacherId', { 
        params: {
          teacherId: teacherId
        }
    })
  },

  updateInfo (teachingTimeSlots: TeachingTimeSlots) {
    return service.put<Result<TeachingTimeSlots>>(api + '/updateInfo', teachingTimeSlots)
  },

  toggleTimeSlotBooked (toggleTimeSlotBooked: ToggleTimeSlotBooked) {
    return service.put<Result<TimeSlot>>(api + '/timeSlot/toggleBooked', toggleTimeSlotBooked)
  },
}

export default teachingTimeSlotsAPI