import { Accent, Country } from "../../../enums"
import { Level } from "../../../teacher"
import { Filters } from "./index"

export interface TeacherFilters extends Filters {
	country: Country
	accent: Accent
	level: Level
	ready: boolean
	locked: boolean
	certified?: boolean
	available?: boolean
	hidden?: boolean
}

export class TeacherFiltersImpl implements TeacherFilters {
	country: Country
	accent: Accent
	level: Level
	ready: boolean
	locked: boolean
	hidden: boolean

	constructor() {
		this.country = Country.ALL
		this.accent = Accent.ALL
		this.level = Level.ALL
		this.ready = true
		this.locked = false
		this.hidden = false
	}

	reset(): void {
		this.country = Country.ALL
		this.accent = Accent.ALL
		this.level = Level.ALL
	}
}