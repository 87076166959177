import { PhoneCountryCode } from "../types/mobile";

export class MobileFilter {
  static region (region: string | undefined, phoneCountryCodes: PhoneCountryCode[]): string {
    if (region) {
      const filteredPhoneCountryCodes = phoneCountryCodes?.filter(phoneCountryCode => {
        return phoneCountryCode.region === region
      })
    
      if (filteredPhoneCountryCodes && filteredPhoneCountryCodes.length > 0) {
        return filteredPhoneCountryCodes[0].country
      } else {
        return ''
      }
    } else {
      return ''
    }
  }
}