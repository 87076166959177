<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.security')" left-arrow @click-left="onClickLeft" />
  <!-- 更新家长邮箱 -->
  <update-parent-email ref="updateParentEmail" :parent="parent" class="mt-4"></update-parent-email>
  <!-- 更新家长手机 -->
  <update-parent-mobile ref="updateParentMobile" :parent="parent" class="mt-4"></update-parent-mobile>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import parentAPI from '../../api/parent'
import UpdateParentEmail from '../../components/me/UpdateParentEmail.vue'
import UpdateParentMobile from '../../components/me/UpdateParentMobile.vue'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, ParentImpl } from '../../types/parent'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.go(-1)
}

const store = useStore()
const parentId = store.getters.id

// 查询家长
const updateParentEmail = ref()
const updateParentMobile = ref()
const parent = reactive<Parent>(new ParentImpl(parentId))
const doFindParent = function () {
  parentAPI.findById(parentId).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
      updateParentEmail.value.refresh(parent)
      updateParentMobile.value.refresh(parent)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

onMounted(() => {
  doFindParent()
})
</script>
