<template>
  <van-cell-group inset class="mt-4">
    <van-cell :title="t('table.arrangement.status')" :value="ArrangementFilter.status(arrangement.status)"></van-cell>
    <van-cell v-if="arrangement.status === Status.CANCELED" :title="t('table.arrangement.cancel-reason')" :value="ArrangementFilter.cancelReason(arrangement.cancelReason)"></van-cell>
    <van-cell :title="t('table.arrangement.time')" :value="DateTimeFilter.datetime(arrangement.startDateTime)"></van-cell>
    <van-cell :title="t('table.arrangement.duration')" :value="CourseFilter.lessonDuration(arrangement.courseLessonDuration)"></van-cell>
    <van-cell :title="t('table.arrangement.student')" :value="arrangement.studentNickname"></van-cell>
    <van-cell :title="t('table.arrangement.teacher')" :value="arrangement.teacherNickname"></van-cell>
    <van-cell :title="t('table.arrangement.course')" :value="arrangement.courseFullName"></van-cell>
    <van-cell :title="t('table.arrangement.lesson')" :value="arrangement.lessonFullName"></van-cell>
    <van-cell :title="t('table.arrangement.type')" :value="CourseFilter.kind(arrangement.courseType, arrangement.trial)"></van-cell>

    <div class="mx-2 my-2 d-flex flex-row justify-content-end">
      <van-button size="small" type="primary" :disabled="arrangement.status !== Status.BOOKED" @click="doJoinClass">{{ t('button.join-class') }}</van-button>
      <van-button size="small" type="primary" :disabled="arrangement.status !== Status.FINISHED || arrangement.reviewed" class="ms-2" :to="{name: 'reviewArrangement', params: {arrangementId: arrangement.id}}">{{ t('button.review') }}</van-button>
      <van-button size="small" type="primary" :disabled="arrangement.status !== Status.BOOKED" class="ms-2" @click="doCancelArrangement(arrangement)">{{ t('button.cancel') }}</van-button>
    </div>
  </van-cell-group>
</template>

<script setup lang="ts">
import { showDialog } from 'vant'
import { useI18n } from 'vue-i18n'
import { ArrangementFilter } from '../../filters/arrangementFilter'
import { CourseFilter } from '../../filters/courseFilter'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { Arrangement, Status } from '../../types/arrangement'

const props = defineProps<{
  arrangement: Arrangement
}>()

const emit = defineEmits<{
  (e: 'cancel', arrangementId: string): void
}>()


const { t } = useI18n({
  useScope: 'global'
})

const doJoinClass = function () {
  showDialog({ message: t('dialog-message.join-class') })
}

const doCancelArrangement = function (arrangement: Arrangement) {
  emit('cancel', arrangement.id)
}
</script>