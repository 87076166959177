<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.recharged')" />

  <!-- 充值结果 -->
  <div class="content">
    <!-- CREATED -->
    <it-result v-if="recharge.status === Status.CREATED" kind="info" :title="t('result.recharged.created.title')" :description="t('result.recharged.created.sub-title')">
      <van-divider></van-divider>

      <div class="mx-4 my-4">
        <van-button round block type="default" @click="doFindRecharge">{{ t('button.refresh') }}</van-button>
      </div>
    </it-result>

    <!-- COMPLETED -->
    <it-result v-if="recharge.status === Status.COMPLETED" kind="success" :title="t('result.recharged.completed.title')" :description="t('result.recharged.completed.sub-title')">
      <van-divider></van-divider>

      <div class="mx-4 my-4">
        <van-button round block type="success" @click="doGotoRecharges">{{ t('button.goto-recharges') }}</van-button>
      </div>
    </it-result>

    <!-- VOIDED -->
    <it-result v-if="recharge.status === Status.VOIDED" kind="warning" :title="t('result.recharged.voided.title')" :description="t('result.recharged.voided.sub-title')">
      <van-divider></van-divider>

      <div class="mx-4 my-4">
        <van-button round block type="warning" @click="doGotoRecharge">{{ t('button.recharge') }}</van-button>
      </div>
    </it-result>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import rechargeAPI from '../../api/recharge'
import ItResult from '../../components/ui/ItResult.vue'
import { Result } from '../../types/common/result'
import { Recharge, RechargeImpl, Status } from '../../types/recharge'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const router = useRouter()
const route = useRoute()

const doGotoRecharges = function () {
  router.push({
    name: 'recharges'
  })
}

const doGotoRecharge = function () {
  router.push({
    name: 'recharge'
  })
}

const loading = ref<boolean>(false)
const rechargeId = route.params.rechargeId as string
const recharge = reactive<Recharge>(new RechargeImpl(rechargeId))
const doFindRecharge  = function () {
  loading.value = true
  rechargeAPI.findById(rechargeId).then((response: AxiosResponse<Result<Recharge>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(recharge, result.data)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  doFindRecharge()
})
</script>