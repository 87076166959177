<template>
  <van-cell-group inset class="mt-4">
    <van-cell :title="t('table.recommendation.referral')" :value="recommendation.referralNickname"></van-cell>
    <van-cell :title="t('table.recommendation.status')" :value="RecommendationFilter.status(recommendation.status)"></van-cell>
    <van-cell :title="t('table.recommendation.time')" :value="DateTimeFilter.datetime(recommendation.dateTime)"></van-cell>
  </van-cell-group>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { DateTimeFilter } from '../../filters/dateTimeFilter'
import { RecommendationFilter } from '../../filters/recommendationFilter'
import { Recommendation } from '../../types/recommendation'

const props = defineProps<{
  recommendation: Recommendation
}>()

const { t } = useI18n({
  useScope: 'global'
})
</script>
