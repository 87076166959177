import { Result } from "../types/common/result"
import { PhoneCountryCode } from "../types/mobile"
import service from "./api-service"

const api = '/mobile'

const mobileAPI = {      
  findAllCountryCode (language: string) {
    return service.get<Result<PhoneCountryCode[]>>(api + '/findAllCountryCode', { 
      params: {
        language: language
      }
    })
  }
}

export default mobileAPI