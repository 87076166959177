import { CancelReason, Status } from "../../../arrangement"
import { Filters } from "./index"

export interface ArrangementFilters extends Filters {
	parentId: string
	teacherId?: string
	courseId?: string
	status: Status
	cancelReason: CancelReason
}

export class ArrangementFiltersImpl implements ArrangementFilters {
	parentId: string
	teacherId?: string
	courseId?: string
	status: Status
	cancelReason: CancelReason

	constructor(parentId: string) {
		this.parentId = parentId
		this.status = Status.ALL
		this.cancelReason = CancelReason.ALL
	}

	reset(): void {
		this.teacherId = undefined
		this.courseId = undefined
		this.status = Status.ALL
		this.cancelReason = CancelReason.ALL
	}
}