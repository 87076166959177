<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.arrangements')" left-arrow @click-left="onClickLeft" />

  <!-- 筛选栏 -->
  <van-dropdown-menu>
    <van-dropdown-item v-model="filters.status" :options="ArrangementStatusOptions.dropdownItemOptions" @change="onStatusChange" />
    <van-dropdown-item v-model="filters.cancelReason" :options="ArrangementCancelReasonOptions.dropdownItemOptions" @change="onCancelReasonChange" />
  </van-dropdown-menu>

  <!-- 列表 -->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list v-model:loading="loading" :finished="finished" :finished-text="t('list.text.finished')" v-model:error="error" :error-text="t('list.text.error')" @load="onLoad">
      <arrangement-card v-for="arrangement in arrangements" :key="arrangement.id" :arrangement="arrangement"></arrangement-card>
    </van-list>
  </van-pull-refresh>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import arrangementAPI from '../../api/arrangement'
import ArrangementCard from '../../components/arrangement/ArrangementCard.vue'
import { useStore } from '../../store'
import { Arrangement } from '../../types/arrangement'
import { ArrangementCancelReasonOptions, ArrangementStatusOptions } from '../../types/common/option/arrangementOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { ArrangementFilters, ArrangementFiltersImpl } from '../../types/common/query/filters/arrangementFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { ArrangementSearcherImpl } from '../../types/common/query/searcher/arrangementSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'me'})
}

const store = useStore()
const parentId = store.getters.id

// Query arrangement
const arrangements = ref<Arrangement[]>([])

const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<ArrangementFilters>(new ArrangementFiltersImpl(parentId))
const searcher = reactive<Searcher>(new ArrangementSearcherImpl())
const sorts = [new SortImpl('startDateTime', Direction.DESC)]

const finished = ref<boolean>(false)
const error = ref<boolean>(false)
const loading = ref<boolean>(false)
const doListArrangement = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  arrangementAPI.list(query).then((response: AxiosResponse<Result<ListObject<Arrangement>>>) => {
    if (refreshing.value) {
      refreshing.value = false
      arrangements.value = []
    }

    const result = response.data
    if (result.success) {
      const listObject = result.data
      listObject.objects.forEach(object => arrangements.value?.push(object))
      
      pagination.currentPage = pagination.currentPage + 1
      pagination.total = listObject.total

      if (arrangements.value?.length! >= pagination.total) finished.value = true
    } else {
      showFailToast(result.message)
      error.value = true
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onCancelReasonChange = function () {
  refreshing.value = true
  pagination.reset()
  doListArrangement()
}

const onStatusChange = function () {
  refreshing.value = true
  pagination.reset()
  doListArrangement()
}

const onLoad = function () {
  doListArrangement()
}

const refreshing = ref<boolean>(false)
const onRefresh = function () {
  finished.value = false
  loading.value = true
  pagination.reset()
  onLoad()
}
</script>
