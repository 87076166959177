import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { CancelEnrollment, CreateEnrollment, Enroll, Enrollment, FinishEnrollment, RearrangeEnrollment, RecreateEnrollment, RestartEnrollment, StartEnrollment, SuspendEnrollment } from "../types/enrollment"
import service from "./api-service"

const api = '/enrollment'

const enrollmentAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Enrollment>>>(api + '/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Enrollment>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  findByStudentId (studentId: string) {
    return service.get<Result<Enrollment[]>>(api + '/findByStudentId', { 
      params: {
        studentId: studentId
      }
    })
  },

  findByStudentIdAndCourseId (studentId: string, courseId: string) {
    return service.get<Result<Enrollment>>(api + '/findByStudentIdAndCourseId', { 
      params: {
        studentId: studentId,
        courseId: courseId
      }
    })
  },

  findEnrollByStudentIdsAndCourseId (studentIds: string[], courseId: string) {
    return service.get<Result<Enroll[]>>(api + '/findEnrollByStudentIdsAndCourseId', { 
      params: {
        studentIds: studentIds,
        courseId: courseId
      }
    })
  },

  findEnrollByParentIdAndCourseId (parentId: string, courseId: string) {
    return service.get<Result<Enroll[]>>(api + '/findEnrollByParentIdAndCourseId', { 
      params: {
        parentId: parentId,
        courseId: courseId
      }
    })
  },

  existsByStudentIdAndCourseId (studentId: string, courseId: string) {
    return service.get<Result<boolean>>(api + '/existsByStudentIdAndCourseId', { 
      params: {
        studentId: studentId,
        courseId: courseId
      }
    })
  },

  existInsufficientSuspendedByParentId (parentId: string) {
    return service.get<Result<boolean>>(api + '/existInsufficientSuspendedByParentId', { 
      params: {
        parentId: parentId
      }
    })
  },

  create (createEnrollment: CreateEnrollment) {
    return service.post<Result<Enrollment>>(api, createEnrollment)
  },

  start (startEnrollment: StartEnrollment) {
    return service.put<Result<Enrollment>>(api + '/start', startEnrollment)
  },

  suspend (suspendEnrollment: SuspendEnrollment) {
    return service.put<Result<Enrollment>>(api + '/suspend', suspendEnrollment)
  },

  cancel (cancelEnrollment: CancelEnrollment) {
    return service.put<Result<Enrollment>>(api + '/cancel', cancelEnrollment)
  },

  finish (finishEnrollment: FinishEnrollment) {
    return service.put<Result<Enrollment>>(api + '/finish', finishEnrollment)
  },
  
  recreate (recreateEnrollment: RecreateEnrollment) {
    return service.put<Result<Enrollment>>(api + '/recreate', recreateEnrollment)
  },

  restart (restartEnrollment: RestartEnrollment) {
    return service.put<Result<Enrollment>>(api + '/restart', restartEnrollment)
  },

  rearrange (rearrangeEnrollment: RearrangeEnrollment) {
    return service.put<Result<Enrollment>>(api + '/rearrange', rearrangeEnrollment)
  },
}

export default enrollmentAPI