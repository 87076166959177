import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Teacher } from "../types/teacher"
import service from "./api-service"

const api = '/teacher'

const teacherAPI = {
  detailList (query: Query) {
    return service.post<Result<ListObject<Teacher>>>(api + '/detail/list', query)
  },
  
  findById (id: string) {
    return service.get<Result<Teacher>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  }
}

export default teacherAPI