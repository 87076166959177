<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.chose-teacher')" left-arrow @click-left="onClickLeft" />

  <!-- 筛选栏 -->
  <van-dropdown-menu>
    <van-dropdown-item v-model="filters.country" :options="CountryOptions.dropdownItemOptions" @change="onCountryChange" />
    <van-dropdown-item v-model="filters.accent" :options="AccentOptions.dropdownItemOptions" @change="onAccentChange" />
    <van-dropdown-item v-model="filters.level" :options="TeacherLevelOptions.dropdownItemOptions" @change="onLevelChange" />
  </van-dropdown-menu>

  <!-- 列表 -->
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <van-list v-model:loading="loading" :finished="finished" :finished-text="t('list.text.finished')" v-model:error="error" :error-text="t('list.text.error')" @load="onLoad">
      <van-cell-group v-for="teacher in teachers" :key="teacher.id" inset class="mt-4">
        <teacher-card :teacher="teacher" :enrollmentId="enrollmentId"></teacher-card>
      </van-cell-group>  
    </van-list>
  </van-pull-refresh>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import teacherAPI from '../../api/teacher'
import TeacherCard from '../../components/teacher/TeacherCard.vue'
import { AccentOptions, CountryOptions } from '../../types/common/option/enumOptions'
import { TeacherLevelOptions } from '../../types/common/option/teacherOptions'
import { Query, QueryImpl } from '../../types/common/query'
import { TeacherFilters, TeacherFiltersImpl } from '../../types/common/query/filters/teacherFilters'
import { Pagination, PaginationImpl } from '../../types/common/query/pagination'
import { Searcher } from '../../types/common/query/searcher'
import { TeacherSearcherImpl } from '../../types/common/query/searcher/teacherSearcher'
import { Direction, SortImpl } from '../../types/common/query/sort'
import { ListObject, Result } from '../../types/common/result'
import { Teacher } from '../../types/teacher'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.go(-1)
}

const route = useRoute()
const enrollmentId = route.params.enrollmentId as string

// Query teacher
const teachers = ref<Teacher[]>([])

const pagination = reactive<Pagination>(new PaginationImpl())
const filters = reactive<TeacherFilters>(new TeacherFiltersImpl())
const searcher = reactive<Searcher>(new TeacherSearcherImpl())
const sorts = [new SortImpl('createdDateTime', Direction.DESC)]

const finished = ref<boolean>(false)
const error = ref<boolean>(false)
const loading = ref<boolean>(false)
const doListTeacher = function () {
  const query: Query = new QueryImpl(pagination, filters, searcher, sorts)
  loading.value = true
  teacherAPI.detailList(query).then((response: AxiosResponse<Result<ListObject<Teacher>>>) => {
    if (refreshing.value) {
      refreshing.value = false
      teachers.value = []
    }

    const result = response.data
    if (result.success) {
      const listObject = result.data
      listObject.objects.forEach(object => teachers.value?.push(object))
      
      pagination.currentPage = pagination.currentPage + 1
      pagination.total = listObject.total

      if (teachers.value?.length! >= pagination.total) finished.value = true
    } else {
      showFailToast(result.message)
      error.value = true
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    loading.value = false
  })
}

const onAvailableChange = function () {
  refreshing.value = true
  pagination.reset()
  doListTeacher()
}

const onCountryChange = function () {
  refreshing.value = true
  pagination.reset()
  doListTeacher()
}

const onAccentChange = function () {
  refreshing.value = true
  pagination.reset()
  doListTeacher()
}

const onLevelChange = function () {
  refreshing.value = true
  pagination.reset()
  doListTeacher()
}

const onCertifiedChange = function () {
  refreshing.value = true
  pagination.reset()
  doListTeacher()
}

const onLoad = function () {
  doListTeacher()
}

const refreshing = ref<boolean>(false)
const onRefresh = function () {
  finished.value = false
  loading.value = true
  pagination.reset()
  onLoad()
}
</script>