<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.course')" left-arrow @click-left="onClickLeft" />

  <!-- 封面 -->
  <van-image :src="course.cover"></van-image>

  <!-- 详情 -->
  <van-tabs>
    <van-tab :title="t('tab.course.info')">
      <course-info :course="course" class="my-4"></course-info>
    </van-tab>
    <!-- TODO -->
    <!-- <van-tab :title="t('tab.course.introduction')">
      <div style="padding: 0 10px;" v-html="course.introduction"></div>
    </van-tab> -->
    <van-tab :title="t('tab.course.lessons')">
      <module-lessons :course-id="course.id" class="my-4"></module-lessons>
    </van-tab>
  </van-tabs>

  <it-placeholder height="40px"></it-placeholder>

  <!-- 报名 TODO 打开自主报名 -->
  <!-- <van-action-bar>
    <van-action-bar-button v-if="course.ready" type="primary" :loading="enrolling" @click="doSelectStudentActionSheet">{{ t('button.enroll') }}</van-action-bar-button>
    <van-action-bar-button v-else type="primary" disabled>{{ CourseFilter.ready(course.ready) }}</van-action-bar-button>
  </van-action-bar> -->

  <!-- 选择学生报名 -->
  <van-action-sheet v-model:show="showSelectStudentActionSheet" :description="t('description.select-student')" :cancel-text="t('button.cancel')" close-on-click-action>
    <van-cell-group>
      <van-cell v-for="enroll in enrolls" :key="enroll.studentId" is-link :title="enroll.studentNickname" :value="EnrollmentFilter.enrolled(enroll.enrolled)" @click="doEnrollCourse(enroll)" />
    </van-cell-group>
  </van-action-sheet>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import courseAPI from '../../api/course'
import enrollmentAPI from '../../api/enrollment'
import CourseInfo from '../../components/course/CourseInfo.vue'
import ModuleLessons from '../../components/course/ModuleLessons.vue'
import ItPlaceholder from '../../components/ui/ItPlaceholder.vue'
import { EnrollmentFilter } from '../../filters/enrollmentFilter'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Course, CourseImpl } from '../../types/course'
import { CreateEnrollmentImpl, Enroll, Enrollment } from '../../types/enrollment'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()
const parentId = store.getters.id

const route = useRoute()
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'courses'})
}

// 查询课程
const course = reactive<Course>(new CourseImpl(route.params.id as string))
const doFindCourse = function () {
  courseAPI.findByIdAndLocale(route.params.id as string, store.getters.locale).then((response: AxiosResponse<Result<Course>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(course, result.data)
    } else {
      showFailToast(result.message)
    }						
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// 查询学生报名情况
const enrolls = ref<Enroll[]>()
const doFindEnrolls = function () {
  enrollmentAPI.findEnrollByParentIdAndCourseId(parentId, course.id).then((response: AxiosResponse<Result<Enroll[]>>) => {
    const result = response.data
    if (result.success) {
      enrolls.value = result.data
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// 选择报名学生
const showSelectStudentActionSheet = ref<boolean>(false)
const doSelectStudentActionSheet = function () {
  showSelectStudentActionSheet.value = true
}

// 报名
const enrolling = ref<boolean>(false)
const doEnrollCourse = function (enroll: Enroll) {
  showSelectStudentActionSheet.value = false
  if (!enroll.enrolled) {
    enrolling.value = true
    const createEnrollment = new CreateEnrollmentImpl(parentId, enroll.studentId, course.id)
    enrollmentAPI.create(createEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
      const result = response.data
      if (result.success) {
        const enrollment = result.data
        router.push({
          name: 'enrolled',
          params: {
            enrollmentId: enrollment.id
          }
        })
      } else {
        showFailToast(result.message)
      }						
    }).catch((error: AxiosError) => {
      showFailToast(error.message)
    }).finally(() => {
      enrolling.value = false
    })
  }
}

onMounted(() => {
  doFindCourse()
  doFindEnrolls()
})
</script>
