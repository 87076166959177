import { DropdownItemOption } from "vant"
import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Status } from "../../recommendation"

const vueI18n = i18n.global

export class RecommendationStatusOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('recommendation.status.all'), Status.ALL),
    new OptionImpl(vueI18n.t('recommendation.status.signup'), Status.SIGNUP),
		new OptionImpl(vueI18n.t('recommendation.status.recharge'), Status.RECHARGE)
	]

	static dropdownItemOptions: DropdownItemOption[] = [
		{text: vueI18n.t('recommendation.status.all'), value: Status.ALL},
		{text: vueI18n.t('recommendation.status.signup'), value: Status.SIGNUP},
		{text: vueI18n.t('recommendation.status.recharge'), value: Status.RECHARGE}
	]
}