<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.enrolled')" />

  <!-- 欢迎 -->
  <div class="content">
    <it-result kind="success" :title="t('result.enrolled.title')" :description="t('result.enrolled.sub-title')">
      <van-divider></van-divider>

      <wechat-service></wechat-service>

      <div class="mx-4 my-4">
        <van-button round block type="success" @click="doGotoArrange">{{ t('button.arrange') }}</van-button>
      </div>
    </it-result>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import WechatService from '../../components/service/WechatService.vue'
import ItResult from '../../components/ui/ItResult.vue'

const { t } = useI18n({
  useScope: 'global'
})

const router = useRouter()
const route = useRoute()

const doGotoArrange = function () {
  router.push({
    name: 'arrange',
    params: {
      enrollmentId: route.params.enrollmentId
    }
  })
}
</script>

<style scoped>
.content {
  height: 100vh;
  background: var(--van-light-green);
}
</style>