<template>
  <van-nav-bar class="navbar" :left-text="brand" @click-right="onClickRight">
    <template #right>
      <van-icon name="wap-nav" size="18" />
    </template>
  </van-nav-bar>

  <!-- Section 1 -->
  <section class="section-1">
    <div class="text-center text-xl" v-html="t('home.section-1.title')"></div>
    <div class="text-center text-xl" v-html="t('home.section-1.sub-title')"></div>
    <div class="text-center mx-4 my-4">{{ t('home.section-1.description') }}</div>
    <div class="mx-12 my-8">
      <van-button class="button" block type="primary" @click="doGotoSignup">{{t('button.signup')}}</van-button>
    </div>
    <van-image class="section-1-image d-block mx-auto" src="/images/home/section-1.png"></van-image>
  </section>

  <!-- Section 2 -->
  <section class="section-2">
    <div class="text-center text-lg">{{ t('home.section-2.title') }}</div>
    <div class="text-center text-md mx-4 mt-4">{{ t('home.section-2.description') }}</div>
  </section>

  <!-- Section 3 -->
  <section class="section-3">
    <div class="text-center text-lg">{{ t('home.section-3.title') }}</div>
    <div class="text-center text-md mx-4 mt-4">{{ t('home.section-3.description') }}</div>
  </section>

  <!-- Section 4 -->
  <section class="section-4">
    <div class="text-center text-lg">{{ t('home.section-4.title') }}</div>
    <div class="text-center text-md mx-4 mt-4">{{ t('home.section-4.description') }}</div>
  </section>

  <!-- Section 5 -->
  <section class="section-5">
    <div class="text-center text-lg">{{ t('home.section-5.title') }}</div>
    <div class="text-center text-md mx-4 mt-4">{{ t('home.section-5.description') }}</div>
    <div class="mx-12 my-8">
      <van-button class="button" block type="primary" @click="doGotoSignup">{{t('button.signup')}}</van-button>
    </div>
  </section>

  <!-- footer -->
  <div class="footer">
    <div class="text-md text-center text-gray-6">©2021 {{ company }}. All Rights Reserved</div>
  </div>
  
  <change-locale-popup v-model:show="showChangeLocalePopup"></change-locale-popup>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import ChangeLocalePopup from '../../components/me/ChangeLocalePopup.vue'
import { Mutations, useStore } from '../../store'

const { t } = useI18n({
  useScope: 'global'
})

const company = import.meta.env.VITE_APP_COMPANY
const brand = import.meta.env.VITE_APP_BRAND

const route = useRoute()
const router = useRouter()
const store = useStore()
router.isReady().then(() => {
  if(route.params.promoteCode) store.commit(Mutations.SET_PROMOTE_CODE, route.params.promoteCode)
})

const showChangeLocalePopup = ref<boolean>(false)

const onClickRight = function () {
  showChangeLocalePopup.value = true
}

const doGotoSignup = function () {
  if (store.getters.isAuth) {
    router.push({name: 'welcome'})
  } else {
    router.push({name: 'auth'})
  }
}
</script>

<style scoped>
.navbar {
  --van-nav-bar-background: var(--van-primary-color);
  --van-nav-bar-icon-color: var(--van-white);
  --van-nav-bar-text-color: var(--van-white);
}

[class*=van-hairline]:after {
  border: 0 !important;
}

.section-1 {
  background-color: var(--van-primary-color);
  color: var(--van-white);
  padding-top: 50px;
}

.section-1 button {
  --van-button-primary-border-color: var(--van-white);
}

.section-1-image {
  width: 160px;
}

.section-2 {
  background-color: var(--van-white);
  padding: 50px 0;
}

.section-3 {
  background-color: var(--van-pink);
  color: var(--van-white);
  padding: 50px 0;
}

.section-4 {
  background-color: var(--van-white);
  padding: 50px 0;
}

.section-5 {
  background-color: var(--van-yellow);
  color: var(--van-white);
  padding: 50px 0;
}

.section-5 button {
  --van-button-primary-border-color: var(--van-white);
  --van-button-primary-background: var(--van-yellow);
}

.footer {
  padding-top: 10px;
}
</style>
