import axios, { AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponseHeaders, InternalAxiosRequestConfig } from "axios"
import { store } from "../store"

const requestTransformer = function (data: any, headers?: AxiosRequestHeaders): any {
	return JSON.stringify(data, (key: string, value: any) => {
		if (key === 'timeSlots') {
			return Object.fromEntries(value)
		} else {
			return value
		}
	})
}

const responseTransformer = function (data: any, headers?: AxiosResponseHeaders): any {
  return JSON.parse(data, (key: string, value: any) => {
    if (key === 'birthday' 
			|| key === 'startTime' || key === 'endTime' || key === 'dateTime' || key === 'date'
			|| key === 'startDateTime' || key === 'createdDateTime' || key === 'completedDateTime' || key === 'voidedDateTime'
			|| key === 'acquiredDate' || key === 'expiredDate') {
			if (value) {
				return new Date(value)
			} else {
				return value
			}
    } else if (key === 'timeSlots') {
			return new Map(Object.entries(value))
		} else {
      return value
    }
  })
}

const config: AxiosRequestConfig = {
	baseURL: import.meta.env.VITE_API_URL as string,
	responseType: 'json',
	headers: { 
		'Access-Control-Allow-Origin': '*',
		'Content-Type': 'application/json; charset=utf-8',
		'Client': 'Parent'
	},
	transformRequest: [requestTransformer],
	transformResponse: [responseTransformer]
}

const service: AxiosInstance = axios.create(config)

// request拦截器
service.interceptors.request.use(
	(config: InternalAxiosRequestConfig<any>) => {
		if (store.getters.isAuth && config.headers) {
			config.headers!.Authorization = 'Bearer ' + store.state.accessToken
		}

		// 如果是get或者delete请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
		if (config.method === 'get' || config.method === 'delete') {
			config.paramsSerializer = {
				indexes: null // by default: false
			}
		}

		return config
	},
	(error: any) => Promise.reject(error)
)

// response拦截器
// service.interceptors.response.use(
// 	(response: AxiosResponse) => Promise.resolve(response),
// 	(error: any) => {
// 		const response = error.response
// 		if (response) {
// 			if (response.status === 401) { // 未认证
// 				router.push({
// 					name: 'auth'
// 				})
// 			} else if (response.status === 403) { // 无访问权限
// 				router.push({
// 					name: 'notAuthorized'
// 				})
// 			} else {
// 				showFailToast(error.message)
// 			}
// 		}
// 		return Promise.reject(error)
// 	}
// )

export default service