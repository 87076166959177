import i18n from "../i18n"

const vueI18n = i18n.global

export class SerieFilter {

  static major (major?: boolean): string {
    if (major == undefined) return ''
    else return major? vueI18n.t('serie.major.yes') : vueI18n.t('serie.major.no')
  }
}