import { DayOfWeek } from "./enums";

export enum Status {
  FREE = 'FREE',
  AVAILABLE = 'AVAILABLE',
  BOOKED = 'BOOKED'
}

export interface TimeSlot {
  dayOfWeek: DayOfWeek
  startTime: Date
  endTime: Date
  index: number
  status: Status
  enrollmentId: string
}

export class TimeSlotImpl implements TimeSlot {
  dayOfWeek: DayOfWeek
  startTime: Date
  endTime: Date
  index: number
  status: Status
  enrollmentId: string

  constructor(dayOfWeek: DayOfWeek, startTime: Date, endTime: Date, index: number) {
    this.dayOfWeek = dayOfWeek
    this.startTime = startTime
    this.endTime = endTime
    this.index = index
    this.status = Status.FREE
    this.enrollmentId = ''
  }
}

export interface TeachingTimeSlots {
  id: string
  teacherId: string
  timeSlots: Map<DayOfWeek, Array<TimeSlot>>
}

export class TeachingTimeSlotsImpl implements TeachingTimeSlots {
  id: string = ''
  teacherId: string
  timeSlots: Map<DayOfWeek, Array<TimeSlot>> = new Map()

  constructor(teacherId: string) {
    this.teacherId = teacherId
  }
}

export interface ToggleTimeSlotBooked {
  id: string
  enrollmentId: string
  dayOfWeek: DayOfWeek
  index: number
}

export class ToggleTimeSlotBookedImpl implements ToggleTimeSlotBooked {
  id: string
  enrollmentId: string
  dayOfWeek: DayOfWeek
  index: number

  constructor(id: string, enrollmentId: string, dayOfWeek: DayOfWeek, index: number) {
    this.id = id
    this.enrollmentId = enrollmentId
    this.dayOfWeek = dayOfWeek
    this.index = index
  }
}