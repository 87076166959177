<template>
  <van-field v-model="props.value" :name="name" :label="label" :placeholder="placeholder" :rules="rules" :input-align="inputAlign" is-link readonly @click="doShowPicker" />
  <van-popup v-model:show="showPicker" round position="bottom">
    <van-picker :columns="options" @cancel="onCancel" @confirm="onConfirm" />
  </van-popup>
</template>

<script setup lang="ts">
import { FieldRule, FieldTextAlign, PickerConfirmEventParams, PickerOption } from 'vant';
import { ref } from 'vue';

const props = defineProps<{
  label?: string
  name?: string
  placeholder?: string
  value?: string | number
  rules?: FieldRule[]
  inputAlign?: FieldTextAlign
  options: PickerOption[]
}>()

const emit = defineEmits<{
  (e: 'confirm', selectedOptions: (PickerOption | undefined)[]): void
  (e: 'cancel'): void
}>()

const showPicker = ref<boolean>(false)

const doShowPicker = function () {
  showPicker.value = true
}

const doHidePicker = function () {
  showPicker.value = false
}

const onCancel = function () {
  doHidePicker()
  emit('cancel')
}

const onConfirm = function(event: PickerConfirmEventParams) {
  doHidePicker()
  emit('confirm', event.selectedOptions)
}
</script>
