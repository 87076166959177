<template>
  <it-picker input-align="right" :label="t('label.time-zone')" :value="updateParentZoneId.zoneId" :options="pickerOptions" @confirm="onConfirm"></it-picker>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { PickerOption, showFailToast, showSuccessToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import zoneIdAPI from '../../api/zoneId'
import { Actions, useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, UpdateParentZoneId, UpdateParentZoneIdImpl } from '../../types/parent'
import ItPicker from '../ui/ItPicker.vue'

const props = defineProps<{
  parentId: string,
  parentZoneId: string
}>()

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()

const pickerOptions = ref<PickerOption[]>([])
const doConvertZoneIds = function (zoneIds: string[]): PickerOption[] {
  let pickerOptions: PickerOption[] = []
  zoneIds.forEach(zoneId => {
    pickerOptions.push({ text: zoneId, value: zoneId })
  })
  return pickerOptions
}

const onConfirm = function(selectedOptions: (PickerOption | undefined)[]) {
  updateParentZoneId.zoneId = selectedOptions[0]?.value as string
  doUpdateParentZoneId()
}

// 查找时区
const doFindAvailableZoneIds = function () {
  zoneIdAPI.findAvailable().then((response: AxiosResponse<Result<string[]>>) => {
    const result = response.data
    if (result.success) {
      pickerOptions.value = doConvertZoneIds(result.data)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// 更新时区
const updateParentZoneId = reactive<UpdateParentZoneId>(new UpdateParentZoneIdImpl(props.parentId, props.parentZoneId))
const doUpdateParentZoneId = function () {
  parentAPI.updateZoneId(updateParentZoneId).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      const parent: Parent = result.data
      store.dispatch(Actions.CHANGE_ZONE_ID, parent.zoneId)
      showSuccessToast(t('message.set-time-zone-success'))
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

onMounted(() => {
  doFindAvailableZoneIds()
})
</script>
