import * as Sentry from '@sentry/browser'
import { Settings } from 'luxon'
import { ConfigProviderTheme } from 'vant'
import { InjectionKey } from 'vue/dist/vue.js'
import { useStore as baseUseStore, createStore, Store } from 'vuex'
import storage from '../storage'
import { AuthParent, ParentAuthed } from '../types/auth'
import { State, StateImpl } from '../types/common/state'
import { Parent } from '../types/parent'
import { changeVantLocale } from '../utils/localUtils'

export enum Mutations {
	AUTH = "auth",
	LOGOUT = "logout",
	COMPLETE_PARENT = "completeParent",
	SET_PROMOTE_CODE = "setPromoteCode",
	UPDATE_PARENT = "updateParent",
	CHANGE_LOCALE = "changeLocale",
	CHANGE_ZONE_ID = "changeZoneId",
	CHANGE_THEME = "changeTheme"
}

export enum Actions {
	AUTH = "auth",
	LOGOUT = "logout",
	COMPLETE_PARENT = "completeParent",
	UPDATE_PARENT = "updateParent",
	CHANGE_LOCALE = "changeLocale",
	CHANGE_ZONE_ID = "changeZoneId",
	CHANGE_THEME = "changeTheme"
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore({
	strict: true,
	state: new StateImpl(),
	getters: {
		isAuth: (state: State): boolean => {
			return state.accessToken !== undefined
		},
		isComplete: (state: State): boolean => {
			return state.parent.complete!
		},
		id: (state: State): string => {
			return state.parent.id!
		},
		zoneId: (state: State): string => {
			return state.parent.zoneId!
		},
		locale: (state: State): string => {
			return state.parent.locale!
		},
		promoteCode: (state: State): string | undefined => {
			return state.promoteCode
		},
		theme: (state: State): ConfigProviderTheme => {
			return state.theme
		},
	},
	// 同步动作
	mutations: {
		// 登录
		[Mutations.AUTH] (state: State, authed: ParentAuthed) {
			state.parent.id = authed.parent.id
			state.parent.nickname = authed.parent.nickname
			state.parent.locked = authed.parent.locked
			state.parent.complete = authed.parent.complete

			storage.setParent(state.parent)

			state.accessToken = authed.accessToken
			storage.setAccessToken(state.accessToken)

			changeVantLocale(state.parent.locale)

			Sentry.setUser({
				id: state.parent.id,
				username: state.parent.nickname
			})
		},

		// 更改推荐码
		[Mutations.SET_PROMOTE_CODE] (state: State, promoteCode: string) {
			state.promoteCode = promoteCode
			storage.setPromoteCode(state.promoteCode)
		},

		// 退出
		[Mutations.LOGOUT] (state: State) {
			// 清空state
			state.parent = new AuthParent()
			state.promoteCode = undefined
			state.accessToken = undefined

			// 清空storage
			storage.clean()

			Sentry.configureScope(scope => scope.setUser(null))
		},

		// 完善家长信息
		[Mutations.COMPLETE_PARENT] (state: State, parent: Parent) {
			state.parent.complete = true
			storage.setParent(state.parent)
		},

		// 更新家长信息
		[Mutations.UPDATE_PARENT] (state: State, parent: Parent) {
			state.parent.nickname = parent.nickname
			storage.setParent(state.parent)
		},

		// 更改语言
		[Mutations.CHANGE_LOCALE] (state: State, locale: string) {
			if (state.parent.locale != locale) {
				state.parent.locale = locale
				Settings.defaultLocale = locale
				changeVantLocale(locale)
				storage.setParent(state.parent)
			}
		},

		// 更改时区
		[Mutations.CHANGE_ZONE_ID] (state: State, zoneId: string) {
			if (state.parent.zoneId !== zoneId) {
				state.parent.zoneId = zoneId
				Settings.defaultZone = zoneId
				storage.setParent(state.parent)
			}
		},

		// 更改主题
		[Mutations.CHANGE_THEME] (state: State, theme: ConfigProviderTheme) {
			state.theme = theme
			storage.setTheme(state.theme)
		}
	},
	// 异步动作
	actions: {
		async [Actions.AUTH] ({ commit }, authed: ParentAuthed) {
			commit(Mutations.AUTH, authed)
		},
		async [Actions.LOGOUT] ({ commit }) {
			commit(Mutations.LOGOUT)
		},
		async [Actions.COMPLETE_PARENT] ({ commit }, parent: Parent) {
			commit(Mutations.COMPLETE_PARENT, parent)
		},
		async [Actions.UPDATE_PARENT] ({ commit }, parent: Parent) {
			commit(Mutations.UPDATE_PARENT, parent)
		},
		async [Actions.CHANGE_LOCALE] ({ commit }, locale: string) {
			commit(Mutations.CHANGE_LOCALE, locale)
		},
		async [Actions.CHANGE_ZONE_ID] ({ commit }, zoneId: string) {
			commit(Mutations.CHANGE_ZONE_ID, zoneId)
		},
		async [Actions.CHANGE_THEME] ({ commit }, theme: string) {
			commit(Mutations.CHANGE_THEME, theme)
		}
	}
})

export function useStore () {
	return baseUseStore(key)
}
