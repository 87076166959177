import { Query } from "../types/common/query"
import { ListObject, Result } from "../types/common/result"
import { Recharge, UserCheckout } from "../types/recharge"
import service from "./api-service"

const api = '/recharge'

const rechargeAPI = {
  list (query: Query) {
    return service.post<Result<ListObject<Recharge>>>(api + '/list', query)
  },
    
  findById (id: string) {
    return service.get<Result<Recharge>>(api + '/findById', { 
      params: {
        id: id
      }
    })
  },

  userCheckout (userCheckout: UserCheckout) {
    return service.post<Result<Recharge>>(api + '/checkout/user', userCheckout)
  }
}

export default rechargeAPI