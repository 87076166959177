<template>
  <!-- Navbar -->
  <van-nav-bar :title="t('title.settings')" left-arrow @click-left="onClickLeft" />

  <!-- Settings -->
  <van-cell-group inset class="mt-4">
    <update-parent-locale :parentId="parentId"></update-parent-locale>
    <update-parent-time-zone :parentId="parentId" :parentZoneId="parentZoneId"></update-parent-time-zone>
  </van-cell-group>

  <!-- Logout -->
  <div class="mx-4 my-4">
    <van-button round block type="danger" @click="doLogout">{{ t('button.logout') }}</van-button>
  </div>

  <!-- Version -->
  <div class="text-center mt-3 text-gray-6 text-md">- {{ version }} -</div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import config from '../../../package.json'
import UpdateParentLocale from '../../components/me/UpdateParentLocale.vue'
import UpdateParentTimeZone from '../../components/me/UpdateParentTimeZone.vue'
import { Actions, useStore } from '../../store'


const { t } = useI18n({
  useScope: 'global'
})

const version = computed(() => config.version)

const store = useStore()
const parentId = store.getters.id
const parentZoneId = store.getters.zoneId

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.go(-1)
}

// 退出登录
const doLogout = function() {
  store.dispatch(Actions.LOGOUT).then(() => {
    router.push({	name: 'home' })
  })
}
</script>
