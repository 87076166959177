<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.chose-time-slots')" left-arrow @click-left="onClickLeft" />

  <!-- 老师信息 -->
  <teacher-brief :teacher="teacher"></teacher-brief>

  <van-tabs>
    <van-tab :title="t('tab.teacher.time')">
      <time-slots class="mt-4" :teacher="teacher" :enrollment-id="enrollmentId"></time-slots>

      <div class="mx-4">
        <van-button v-if="enrollment.status === Status.CREATED" round block type="primary" @click="doStartEnrollment">{{t('button.start')}}</van-button>
        <van-button v-else-if="enrollment.status === Status.SUSPENDED" round block type="primary" @click="doRestartEnrollment">{{t('button.restart')}}</van-button>
        <van-button v-else round block type="primary" @click="doGoBack">{{t('button.back')}}</van-button>
        <div class="text-center mt-2 text-md" style="color: red;">{{ t('note.enrollment.start') }}</div>
      </div>
    </van-tab>
    <van-tab :title="t('tab.teacher.info')">
      <teacher-info class="my-4" :teacher="teacher"></teacher-info>
    </van-tab>
    <van-tab :title="t('tab.teacher.introduction')">
      <div class="mx-4 my-4">
        <video :src="teacher.show" controls preload="auto" style="width: 100%;"></video>
        <div class="mt-4">{{ teacher.introduction }}</div>
      </div>
    </van-tab>
    <van-tab v-if="teacher.certified" :title="t('tab.teacher.certificates')">
      <van-image class="mt-4" v-for="(certificate, index) in teacher.certificates" :key="index" :src="certificate.photo" :alt="certificate.name" />
    </van-tab>
  </van-tabs>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast, showSuccessToast } from 'vant'
import { onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import enrollmentAPI from '../../api/enrollment'
import teacherAPI from '../../api/teacher'
import TimeSlots from '../../components/schedule/TimeSlots.vue'
import TeacherBrief from '../../components/teacher/TeacherBrief.vue'
import TeacherInfo from '../../components/teacher/TeacherInfo.vue'
import { Result } from '../../types/common/result'
import { Enrollment, EnrollmentImpl, RestartEnrollmentImpl, StartEnrollmentImpl, Status } from '../../types/enrollment'
import { Teacher, TeacherImpl } from '../../types/teacher'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const route = useRoute()
const teacherId = route.params.id as string
const enrollmentId = route.params.enrollmentId as string

const router = useRouter()
const onClickLeft = function () {
  router.go(-1)
}

// 查询老师
const teacher = reactive<Teacher>(new TeacherImpl(teacherId))
const doFindTeacher = function () {
  teacherAPI.findById(route.params.id as string).then((response: AxiosResponse<Result<Teacher>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(teacher, result.data)
    } else {
      showFailToast(result.message)
    }						
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// find enrollment
const enrollment = reactive<Enrollment>(new EnrollmentImpl(enrollmentId))
const doFindEnrollment = function() {
  enrollmentAPI.findById(enrollmentId).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(enrollment, result.data)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// start enrollment
const doStartEnrollment = function() {
  const startEnrollment = new StartEnrollmentImpl(enrollmentId)
  enrollmentAPI.start(startEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      showSuccessToast(t('message.start-enrollment-success'))
      router.push({
        name: 'enrollments'
      })
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// Restart Enrollment
const doRestartEnrollment = function() {
  const restartEnrollment = new RestartEnrollmentImpl(enrollmentId)
  enrollmentAPI.restart(restartEnrollment).then((response: AxiosResponse<Result<Enrollment>>) => {
    const result = response.data
    if (result.success) {
      showSuccessToast(t('message.restart-enrollment-success'))
      router.push({
        name: 'enrollments'
      })
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const doGoBack = function() {
  router.push({
    name: 'enrollments'
  })
}

onMounted(() => {
  doFindTeacher()
  doFindEnrollment()
})
</script>
