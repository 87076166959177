<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.welcome')" />

  <!-- 欢迎 -->
  <div class="content">
    <it-result kind="success" :title="t('result.signup.title')" :description="t('result.signup.sub-title')">
      <van-divider></van-divider>

      <wechat-service></wechat-service>
      
      <div class="mx-4 my-4">
        <van-button round block type="success" @click="doGotoHome">{{ t('button.goto-home') }}</van-button>
      </div>
    </it-result>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import WechatService from '../components/service/WechatService.vue';
import ItResult from '../components/ui/ItResult.vue';

const { t } = useI18n({
  useScope: 'global'
})

const router = useRouter()

const doGotoHome = function () {
  router.push({
    name: 'home'
  })
}
</script>

<style scoped>
.content {
  height: 100vh;
  background: var(--van-light-green);
}
</style>