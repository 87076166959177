<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.student-profile')" left-arrow @click-left="onClickLeft" />

  <!-- 更新学生头像 -->
  <update-student-avatar ref="updateStudentAvatar" :student="student" class="mt-4"></update-student-avatar>

  <!-- 更新学生信息 -->
  <update-student-info ref="updateStudentInfo" :student="student" class="mt-4"></update-student-info>

  <!-- 删除学生 -->
  <div class="mx-4 my-4">
    <van-button round block type="danger" :loading="deleting" @click="doDeleteStudent">{{ t('button.delete') }}</van-button>
  </div>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast, showSuccessToast } from 'vant'
import { onMounted, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import studentAPI from '../../api/student'
import UpdateStudentAvatar from '../../components/student/UpdateStudentAvatar.vue'
import UpdateStudentInfo from '../../components/student/UpdateStudentInfo.vue'
import { Result } from '../../types/common/result'
import { Student, StudentImpl } from '../../types/student'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.push({name: 'students'})
}

const route = useRoute()
const studentId = route.params.id as string

// 查询学生
const updateStudentAvatar = ref()
const updateStudentInfo = ref()
const student = reactive<Student>(new StudentImpl(studentId))
const doFindStudent = function () {
  studentAPI.findById(studentId).then((response: AxiosResponse<Result<Student>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(student, result.data)
      updateStudentAvatar.value.refresh(student)
      updateStudentInfo.value.refresh(student)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

// 删除学生
const deleting = ref<boolean>(false)
const doDeleteStudent = function () {
  deleting.value = true
  studentAPI.deleteById(studentId).then((response: AxiosResponse<Result<void>>) => {
    const result = response.data
    if (result.success) {
      showSuccessToast(t('message.delete-student-success'))
      router.push({name: 'students'})
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  }).finally(() => {
    deleting.value = false
  })
}

onMounted(() => {
  doFindStudent()
})
</script>