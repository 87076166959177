import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'
import { store } from '../store'

const i18n = createI18n({
  locale: store.state.parent.locale,
  fallbackLocale: 'en',
  messages
})

export default i18n