<template>
  <!-- 家长信息 -->
  <parent-brief></parent-brief>

  <van-notice-bar left-icon="volume-o" mode="link" @click="doInvite">{{ t('description.invite') }}</van-notice-bar>

  <van-grid :border="false" :column-num="3" :gutter="10" class="mt-4">
    <van-grid-item>
      <h2 class="mb-0 mt-4">{{ parent.balanceLessons }}</h2>
      <p class="text-md">{{ t('description.account-balance') }}</p>
    </van-grid-item>
    <van-grid-item>
      <h2 class="mb-0 mt-4">{{ parent.totalRechargeLessons }}</h2>
      <p class="text-md">{{ t('description.total-recharge') }}</p>
    </van-grid-item>
    <van-grid-item>
      <h2 class="mb-0 mt-4">{{ parent.totalConsumeLessons }}</h2>
      <p class="text-md">{{ t('description.total-consume') }}</p>
    </van-grid-item>
  </van-grid>

  <van-cell-group class="mt-4">
    <!-- TODO -->
    <van-cell icon="pending-payment" :title="t('menu-item.recharge')" is-link :to="{name: 'recharge'}" />
    <van-cell icon="after-sale" :title="t('menu-item.recharges')" is-link :to="{name: 'recharges'}" />
    <van-cell icon="cash-back-record" :title="t('menu-item.consumes')" is-link :to="{name: 'consumes'}" />
    <van-cell icon="share-o" :title="t('menu-item.recommendations')" is-link :to="{name: 'recommendations'}" />
  </van-cell-group>

  <van-cell-group class="mt-4">
    <van-cell icon="completed" :title="t('menu-item.enrollments')" is-link :to="{name: 'enrollments'}" />
    <van-cell icon="orders-o" :title="t('menu-item.arrangements')" is-link :to="{name: 'arrangements'}" />
  </van-cell-group>

  <van-cell-group class="mt-4">
    <van-cell icon="friends-o" :title="t('menu-item.students')" is-link :to="{name: 'students'}" />
    <van-cell icon="coupon-o" :title="t('menu-item.coupons')" is-link :to="{name: 'coupons'}" />
    <van-cell icon="service-o" :title="t('menu-item.service')" is-link :to="{name: 'service'}" />
    <van-cell icon="closed-eye" :title="t('menu-item.security')" is-link :to="{name: 'security'}" />
    <van-cell icon="setting-o" :title="t('menu-item.settings')" is-link :to="{name: 'settings'}" />
  </van-cell-group>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { showFailToast } from 'vant'
import { onMounted, reactive } from 'vue'
import { useI18n } from 'vue-i18n'
import parentAPI from '../../api/parent'
import ParentBrief from '../../components/me/ParentBrief.vue'
import router from '../../router'
import { useStore } from '../../store'
import { Result } from '../../types/common/result'
import { Parent, ParentImpl } from '../../types/parent'
import { cloneFrom } from '../../utils/clone'

const { t } = useI18n({
  useScope: 'global'
})

const store = useStore()
const parentId = store.getters.id

const parent = reactive<Parent>(new ParentImpl(parentId))
const doFindParent = function () {
  parentAPI.findById(parentId).then((response: AxiosResponse<Result<Parent>>) => {
    const result = response.data
    if (result.success) {
      cloneFrom(parent, result.data)
    } else {
      showFailToast(result.message)
    }
  }).catch((error: AxiosError) => {
    showFailToast(error.message)
  })
}

const doInvite = function () {
  router.push({name: 'invite'})
}

onMounted(() => {
  doFindParent()
})
</script>
