export enum Type {
	VIP = 'VIP',
	GROUP = 'GROUP'
}

export interface Course {
	id: string
	name?: string
	fullName?: string
	type?: Type
	cover?: string
	introduction?: string
	lessonDuration?: number
	lessonCount?: number
	ready?: boolean
	
	serieId?: string
	serieName?: string
}

export class CourseImpl implements Course {
	id: string

	constructor(id: string) {
		this.id = id
	}
}

export interface SerieCourse {
	id: string
	name?: string
	cover?: string
	ready?: boolean
	serieId?: string
}