export enum Status {
	ACQUIRED = 'ACQUIRED',
	USED = 'USED',
  EXPIRED = 'EXPIRED'
}

export interface AcquiredCoupon {
  id: string
  status?: Status
  
  parentId?: string
  parentName?: string
  parentNickname?: string

  couponId?: string
  couponName?: string
  couponDescription?: string
  couponValue?: number
  
  acquiredDate?: Date
  expiredDate?: Date
}

export class AcquiredCouponImpl implements AcquiredCoupon {
	id: string

	constructor(id: string) {
		this.id = id
	}
}