
import { Locale } from "vue-i18n"
import storage from "../storage"
import { store } from "../store"

export class ParentAuth {
	promoteCode?: string
	locale: string = store.state.parent.locale
	zoneId: string = store.state.parent.zoneId
}

export class ParentEmailOtpAuth extends ParentAuth {
	email?: string
	otp?: string
}

export class EmailPasswordAuth extends ParentAuth {
	email?: string
	password?: string
}

export class ParentMobileOtpAuth extends ParentAuth {
	region?: string
	countryCode?: string
	mobile?: string
	otp?: string
}

export class AuthParent {
	id?: string
	nickname?: string
  locale: Locale
  zoneId: string
  complete?: boolean
	locked?: boolean

	constructor() {
		this.locale = storage.getParent()?.locale || navigator.language
		this.zoneId = storage.getParent()?.zoneId || Intl.DateTimeFormat().resolvedOptions().timeZone
	}
}

export interface ParentAuthed {
	accessToken: string
	parent: AuthParent
}