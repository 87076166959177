<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.review-arrangement')" left-arrow @click-left="onClickLeft" />

  <!-- Form -->
  <van-form ref="reviewArrangementForm" class="mt-4">
    <van-cell-group inset>
      <!-- Alert -->
      <div v-if="message" class="alert alert-danger">{{ message }}</div>
      <!-- Rank -->
      <van-field name="rank" :label="t('label.rank')" :rules="[{ validator: mustSelectValidator, trigger: 'onChange' }]">
        <template #input>
          <van-rate v-model="reviewArrangement.rank" color="#ffd21e" void-icon="star" void-color="#eee"></van-rate>
        </template>
      </van-field>
      <!-- Feedback -->
      <van-field type="textarea" v-model.trim="reviewArrangement.feedback" name="feedback" :label="t('label.feedback')" clearable rows="5" autosize maxlength="100" show-word-limit></van-field>
    </van-cell-group>

    <!-- Button -->
    <div class="mx-4 my-4">
      <van-button round block type="primary" :loading="reviewing" @click="doReviewArrangement">{{ t('button.review') }}</van-button>
    </div>
  </van-form>
</template>

<script setup lang="ts">
import { AxiosError, AxiosResponse } from 'axios'
import { FormInstance, showFailToast } from 'vant'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import arrangementAPI from '../../api/arrangement'
import { Arrangement, ReviewArrangement, ReviewArrangementImpl } from '../../types/arrangement'
import { Result } from '../../types/common/result'
import { mustSelectValidator } from '../../validators'

const { t } = useI18n({
  useScope: 'global'
})

// 返回
const router = useRouter()
const onClickLeft = function () {
  router.go(-1)
}

const route = useRoute()
const arrangementId = route.params.arrangementId as string

const message = ref<string>('')

const reviewArrangement = reactive<ReviewArrangement>(new ReviewArrangementImpl(arrangementId))
const reviewArrangementForm = ref<FormInstance>()

// 新建学生
const reviewing = ref<boolean>(false)
const doReviewArrangement = function () {
  reviewArrangementForm.value?.validate().then(() => {
    reviewing.value = true
    arrangementAPI.review(reviewArrangement).then((response: AxiosResponse<Result<Arrangement>>) => {
      const result = response.data
      if (result.success) {
        router.go(-1)
      } else {
        message.value = result.message
      }
    }).catch((error: AxiosError) => {
      showFailToast(error.message)
    }).finally(() => {
      reviewing.value = false
    })
  }).catch(() => {})
}
</script>
