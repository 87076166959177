export interface RechargeCard {
	id: string
	name?: string
	price?: number
	discountPrice?: number
	lessons?: number
	recommended?: boolean
	hot?: boolean
	published?: boolean
	stripeProductId?: string

	reset(): void
}

export class RechargeCardImpl implements RechargeCard {
	id: string

	constructor(id: string) {
		this.id = id
	}

	reset(): void {
		this.id = ''
	}
}