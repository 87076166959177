<template>
  <!-- 导航栏 -->
  <van-nav-bar :title="t('title.auth')" left-arrow @click-left="onClickLeft" />

  <!-- 内容 -->
  <div class="d-flex flex-column justify-content-center">
    <!-- Logo -->
    <div class="d-flex justify-content-center" style="margin: 100px 0;">
      <van-image :src="logo" width="100" height="100" radius="10" class="bg-white"></van-image>
    </div>

    <!-- 登录 -->
    <van-tabs type="card">
      <van-tab :title="t('tab.auth.mobile')">
        <mobile-otp-auth></mobile-otp-auth>
      </van-tab>
      <van-tab :title="t('tab.auth.email')">
        <email-otp-auth></email-otp-auth>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import config from '../../package.json'
import EmailOtpAuth from '../components/auth/EmailOtpAuth.vue'
import MobileOtpAuth from '../components/auth/MobileOtpAuth.vue'

const { t } = useI18n({
  useScope: 'global'
})

let logo: string = ''
switch(import.meta.env.VITE_APP_BRAND) {
  case 'CetuSchool': {
    logo = '/images/logo/cetuschool.png'
    break
  }
  case 'DDTalk': {
    logo = '/images/logo/ddtalk.png'
    break
  }
}

const version = computed(() => config.version)

const router = useRouter()

// 返回
const onClickLeft = function () {
  router.push({name: 'home'})
}
</script>