import { Option, OptionImpl } from "."
import i18n from "../../../i18n"
import { Subject } from "../../serie"

const vueI18n = i18n.global

export class SubjectOptions {
	static options: Option[] = [
		new OptionImpl(vueI18n.t('serie.subject.all'), Subject.ALL),
    new OptionImpl(vueI18n.t('serie.subject.english'), Subject.ENGLISH),
		new OptionImpl(vueI18n.t('serie.subject.math'), Subject.MATH),
	]
}