import { Filters } from "./index"

export interface ConsumeFilters extends Filters {
	parentId: string
}

export class ConsumeFiltersImpl implements ConsumeFilters {
	parentId: string

	constructor(parentId: string) {
		this.parentId = parentId
	}

	reset(): void {
		
	}
}