import i18n from "../i18n"
import { Accent, Country, Currency, Gender } from "../types/enums"

const vueI18n = i18n.global

export class EnumFilter {
  static gender (gender: Gender | undefined): string {
    if (gender) {
      switch (gender) {
        case Gender.UNKNOWN: return vueI18n.t('gender.unknown')
        case Gender.BOY: return vueI18n.t('gender.boy')
        case Gender.GIRL: return vueI18n.t('gender.girl')
        default: return ''
      }
    } else {
      return ''
    }
  }

  static country (country: Country | undefined): string {
    if (country) {
      switch (country) {
        case Country.US: return vueI18n.t('country.us')
        case Country.UK: return vueI18n.t('country.uk')
        case Country.CANADA: return vueI18n.t('country.canada')
        default: return ''
      }
    } else {
      return ''
    }
  }

  static accent (accent: Accent | undefined): string {
    if (accent) {
      switch (accent) {
        case Accent.AMERICAN: return vueI18n.t('accent.us')
        case Accent.BRITISH: return vueI18n.t('accent.uk')
        default: return ''
      }
    } else {
      return ''
    }
  }

  static currency (currency: Currency | undefined): string {
    if (currency) {
      switch (currency) {
        case Currency.USD: return "$"
        case Currency.CNY: return "¥"
        default: return ''
      }
    } else {
      return ''
    }
  }
}