<template>
  <acquired-coupon-card v-for="acquiredCoupon in acquiredCoupons" :acquired-coupon="acquiredCoupon" @click="doSelectAcquiredCoupon(acquiredCoupon)"></acquired-coupon-card>
</template>

<script setup lang="ts">
import { AcquiredCoupon } from '../../types/acquiredCoupon';
import AcquiredCouponCard from './AcquiredCouponCard.vue';

const props = defineProps<{
  acquiredCoupons: AcquiredCoupon[]
}>()

const emit = defineEmits<{
  (e: 'select', acquiredCoupon: AcquiredCoupon): void
}>()

const doSelectAcquiredCoupon = function (acquiredCoupon: AcquiredCoupon) {
  emit('select', acquiredCoupon)
}
</script>