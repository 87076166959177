<template>
  <van-field v-model="props.value" :name="name" :label="label" :placeholder="placeholder" :rules="rules" input-align="right" is-link readonly @click="doShowPicker" />

  <van-popup v-model:show="showPicker" round position="bottom">
    <van-date-picker @cancel="onCancel" @confirm="onConfirm" :min-date="minDate" :max-date="maxDate" />
  </van-popup>
</template>

<script setup lang="ts">
import { FieldRule, PickerConfirmEventParams, PickerOption } from 'vant';
import { ref } from 'vue';

const props = defineProps<{
  label?: string
  name?: string
  placeholder?: string
  value?: string
  rules?: FieldRule[]
  date?: string[]
  minDate?: Date
  maxDate?: Date
}>()

const emit = defineEmits<{
  (e: 'confirm', selectedOptions: (PickerOption | undefined)[]): void
  (e: 'cancel'): void
}>()

const showPicker = ref<boolean>(false)
    
const doShowPicker = function () {
  showPicker.value = true
}

const doHidePicker = function () {
  showPicker.value = false
}

const onCancel = function () {
  doHidePicker()
  emit('cancel')
}

const onConfirm = function(event: PickerConfirmEventParams) {
  doHidePicker()
  emit('confirm', event.selectedOptions)
}
</script>
