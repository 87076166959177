import { Method, Status, Type } from "../../../recharge"
import { Filters } from "./index"

export interface RechargeFilters extends Filters {
	parentId: string
	status: Status
	type?: Type
	method?: Method
}

export class RechargeFiltersImpl implements RechargeFilters {
	parentId: string
	status: Status
	type: Type
	method?: Method

	constructor(parentId: string) {
		this.parentId = parentId
		this.status = Status.ALL
		this.type = Type.ALL
	}

	reset(): void {
			this.status = Status.ALL
			this.type = Type.ALL
			this.method = undefined
	}
}