import { FieldRule } from "vant/lib/field"
import i18n from "../i18n"

const vueI18n = i18n.global

export const mobileValidator = function(value: any, rule: FieldRule): boolean | string | Promise<boolean | string> {
	const reg = /^1[3456789]\d{9}$/
	return reg.test(value)? true : vueI18n.t('validator.invalidate-mobile')
} 

export const emailValidator = function(value: any, rule: FieldRule): boolean | string | Promise<boolean | string> {
	const reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
	return reg.test(value)? true : vueI18n.t('validator.invalidate-email')
}

export const passwordValidator = function(value: any, rule: FieldRule): boolean | string | Promise<boolean | string> {
	const reg = /^[a-zA-Z0-9]{6,50}$/
	return reg.test(value)? true : vueI18n.t('validator.invalidate-password')
}

export const otpValidator = function(value: any, rule: FieldRule): boolean | string | Promise<boolean | string> {
	const reg = /^\d{6}$/
	return reg.test(value)? true : vueI18n.t('validator.invalidate-otp')
} 

export const mustInputValidator = function(value: any, rule: FieldRule): boolean | string | Promise<boolean | string> {
	return value? true : vueI18n.t('validator.must-input')
} 

export const mustSelectValidator = function(value: any, rule: FieldRule): boolean | string | Promise<boolean | string> {
	return value? true : vueI18n.t('validator.must-select')
}